// import React from "react";
// import * as XLSX from "xlsx";
// import Swal from "sweetalert2";

// const ExportExcel = ({
//   activeSociety,
//   filteredTransactions,
//   filters,
//   dispatch,
//   fetchTransactions,
//   sortOrder,
// }) => {
//   // Helper function to format date for display
//   const formatDate = (date) => {
//     return new Date(date).toLocaleDateString("en-US", {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//     });
//   };

//   // Function to process and export transaction data
//   const processAndExportData = (transactions) => {
//     if (!Array.isArray(transactions) || transactions.length === 0) {
//       Swal.fire({
//         title: "No Data",
//         text: "No transactions available to export.",
//         icon: "warning",
//         background: "#1E293B",
//         customClass: {
//           popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
//           title: "text-amber-400",
//         },
//       });
//       return;
//     }

//     let totalIncome = 0;
//     let totalExpense = 0;

//     const dataToExport = transactions.map((transaction) => {
//       const amount = transaction.amount || 0;

//       if (
//         transaction.transaction_direction === "in" ||
//         transaction.transaction_direction === "Income"
//       ) {
//         totalIncome += amount;
//       } else if (
//         transaction.transaction_direction === "out" ||
//         transaction.transaction_direction === "Expense"
//       ) {
//         totalExpense += amount;
//       }

//       return {
//         Date: new Date(transaction.transaction_date).toLocaleDateString(),
//         "Receipt No.": transaction.receipt_no || "N/A",
//         "Customer/Employee":
//           transaction.customer_id?.name ||
//           (transaction.customer_info && transaction.customer_info[0]?.name) ||
//           transaction.employee_id?.name ||
//           transaction.user_id?.investorData?.name ||
//           "N/A",
//         Society: activeSociety?.name || "N/A",
//         "Plot Number":
//           transaction.plot_id?.plot_number ||
//           (transaction.plot_info && transaction.plot_info[0]?.plot_number) ||
//           "No Plot",
//         "Transaction Type": transaction.transaction_type || "N/A",
//         Direction: transaction.transaction_direction || "N/A",
//         Method: transaction.payment_method || "N/A",
//         Amount: amount,
//         Status: transaction.status || "N/A",
//         Description: transaction.description || "N/A",
//       };
//     });

//     // Add summary rows
//     dataToExport.push({
//       Date: "",
//       "Receipt No.": "",
//       "Customer/Employee": "",
//       Society: "",
//       "Plot Number": "",
//       "Transaction Type": "",
//       Direction: "Total Income",
//       Method: "",
//       Amount: totalIncome,
//       Status: "",
//       Description: "",
//     });

//     dataToExport.push({
//       Date: "",
//       "Receipt No.": "",
//       "Customer/Employee": "",
//       Society: "",
//       "Plot Number": "",
//       "Transaction Type": "",
//       Direction: "Total Expense",
//       Method: "",
//       Amount: totalExpense,
//       Status: "",
//       Description: "",
//     });

//     dataToExport.push({
//       Date: "",
//       "Receipt No.": "",
//       "Customer/Employee": "",
//       Society: "",
//       "Plot Number": "",
//       "Transaction Type": "",
//       Direction: "Net Balance",
//       Method: "",
//       Amount: totalIncome - totalExpense,
//       Status: "",
//       Description: "",
//     });

//     // Create worksheet
//     const worksheet = XLSX.utils.json_to_sheet(dataToExport);

//     // Get range of worksheet
//     const range = XLSX.utils.decode_range(worksheet["!ref"]);

//     // Create styles
//     const styles = {
//       headerStyle: {
//         fill: { fgColor: { rgb: "20485B" } }, // Dark teal
//         font: { bold: true, color: { rgb: "FFFFFF" } },
//         alignment: { horizontal: "center", vertical: "center" },
//         border: {
//           top: { style: "thin", color: { rgb: "FFFFFF" } },
//           bottom: { style: "thin", color: { rgb: "FFFFFF" } },
//           left: { style: "thin", color: { rgb: "FFFFFF" } },
//           right: { style: "thin", color: { rgb: "FFFFFF" } },
//         },
//       },
//       alternateRowStyle: {
//         fill: { fgColor: { rgb: "F0FDFA" } }, // Light teal
//       },
//       incomeStyle: {
//         font: { color: { rgb: "008000" } }, // Green
//       },
//       expenseStyle: {
//         font: { color: { rgb: "FF0000" } }, // Red
//       },
//       totalRowStyle: {
//         fill: { fgColor: { rgb: "134E4A" } }, // Darker teal
//         font: { bold: true, color: { rgb: "FFFFFF" } },
//         alignment: { horizontal: "center" },
//       },
//       netBalanceStyle: {
//         fill: { fgColor: { rgb: "0F766E" } }, // Dark teal
//         font: { bold: true, color: { rgb: "FFFFFF" }, sz: 12 },
//         alignment: { horizontal: "center" },
//       },
//     };

//     // Apply styles
//     for (let R = 0; R <= range.e.r; R++) {
//       for (let C = 0; C <= range.e.c; C++) {
//         const cellRef = XLSX.utils.encode_cell({ r: R, c: C });

//         // Initialize cell if needed
//         if (!worksheet[cellRef]) {
//           worksheet[cellRef] = { v: "", t: "s" };
//         }

//         // Add style property
//         if (!worksheet[cellRef].s) {
//           worksheet[cellRef].s = {};
//         }

//         // Header row
//         if (R === 0) {
//           worksheet[cellRef].s = styles.headerStyle;
//         }
//         // Alternate row shading
//         else if (R % 2 === 0 && R < range.e.r - 2) {
//           worksheet[cellRef].s = styles.alternateRowStyle;
//         }
//         // Total income row
//         else if (R === range.e.r - 2) {
//           worksheet[cellRef].s = styles.totalRowStyle;

//           // Format amount column
//           if (C === Object.keys(dataToExport[0]).indexOf("Amount")) {
//             worksheet[cellRef].s = {
//               ...worksheet[cellRef].s,
//               ...styles.incomeStyle,
//             };
//           }
//         }
//         // Total expense row
//         else if (R === range.e.r - 1) {
//           worksheet[cellRef].s = styles.totalRowStyle;

//           // Format amount column
//           if (C === Object.keys(dataToExport[0]).indexOf("Amount")) {
//             worksheet[cellRef].s = {
//               ...worksheet[cellRef].s,
//               ...styles.expenseStyle,
//             };
//           }
//         }
//         // Net balance row
//         else if (R === range.e.r) {
//           worksheet[cellRef].s = styles.netBalanceStyle;
//         }

//         // Amount column styling for regular rows
//         if (
//           C === Object.keys(dataToExport[0]).indexOf("Amount") &&
//           worksheet[cellRef].v &&
//           R < range.e.r - 2
//         ) {
//           const transaction = transactions[R - 1];
//           if (transaction) {
//             if (
//               transaction.transaction_direction === "in" ||
//               transaction.transaction_direction === "Income"
//             ) {
//               worksheet[cellRef].s = {
//                 ...worksheet[cellRef].s,
//                 ...styles.incomeStyle,
//               };
//             } else if (
//               transaction.transaction_direction === "out" ||
//               transaction.transaction_direction === "Expense"
//             ) {
//               worksheet[cellRef].s = {
//                 ...worksheet[cellRef].s,
//                 ...styles.expenseStyle,
//               };
//             }
//           }
//         }
//       }
//     }

//     // Set column widths for better readability
//     worksheet["!cols"] = [
//       { wpx: 100 }, // Date
//       { wpx: 120 }, // Receipt No
//       { wpx: 180 }, // Customer/Employee
//       { wpx: 150 }, // Society
//       { wpx: 100 }, // Plot Number
//       { wpx: 150 }, // Transaction Type
//       { wpx: 100 }, // Direction
//       { wpx: 100 }, // Method
//       { wpx: 100 }, // Amount
//       { wpx: 100 }, // Status
//       { wpx: 200 }, // Description
//     ];

//     // Create workbook and add worksheet
//     const workbook = {
//       SheetNames: ["Transactions"],
//       Sheets: {
//         Transactions: worksheet,
//       },
//     };

//     return workbook;
//   };

//   // Function to handle the actual export with specific date range
//   const exportTransactionsWithDateRange = async (
//     startDate,
//     endDate,
//     periodName
//   ) => {
//     try {
//       // Show loading state
//       Swal.fire({
//         title: "Preparing Export",
//         html: `<div class="flex flex-col items-center">
//                 <div class="w-16 h-16 border-t-4 border-teal-500 border-solid rounded-full animate-spin mb-4"></div>
//                 <p class="text-teal-400">Gathering transaction data...</p>
//               </div>`,
//         allowOutsideClick: false,
//         showConfirmButton: false,
//         background: "#1E293B",
//         customClass: {
//           popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
//           title: "text-teal-300",
//         },
//       });

//       // Format dates for API call if provided
//       let formattedStartDate = null;
//       let formattedEndDate = null;

//       if (startDate && endDate) {
//         formattedStartDate = new Date(startDate);
//         formattedStartDate.setHours(0, 0, 0, 0);

//         formattedEndDate = new Date(endDate);
//         formattedEndDate.setHours(23, 59, 59, 999);
//       }

//       // Fetch filtered data based on date range
//       const response = await dispatch(
//         fetchTransactions({
//           societyId: activeSociety?._id,
//           description: filters.description,
//           startDate: formattedStartDate
//             ? formattedStartDate.toISOString()
//             : undefined,
//           endDate: formattedEndDate
//             ? formattedEndDate.toISOString()
//             : undefined,
//           sortOrder,
//         })
//       ).unwrap();

//       // Get transactions data
//       let transactions = [];
//       if (
//         response?.data?.transactions &&
//         Array.isArray(response.data.transactions)
//       ) {
//         transactions = response.data.transactions;
//       }

//       // Process and export data
//       const workbook = processAndExportData(transactions);

//       if (!workbook) {
//         return;
//       }

//       // Generate filename with date range info
//       const societyName = activeSociety
//         ? activeSociety.name.replace(/\s+/g, "_")
//         : "Society";
//       let fileName = "";

//       if (periodName === "custom" && startDate && endDate) {
//         // Format dates for filename
//         const startDateFormatted = new Date(startDate)
//           .toISOString()
//           .slice(0, 10);
//         const endDateFormatted = new Date(endDate).toISOString().slice(0, 10);
//         fileName = `${societyName}_transactions_${startDateFormatted}_to_${endDateFormatted}.xlsx`;
//       } else {
//         // Use period name in filename
//         const today = new Date().toISOString().slice(0, 10);
//         fileName = `${societyName}_transactions_${periodName}_${today}.xlsx`;
//       }

//       // Write file using XLSX
//       XLSX.writeFile(workbook, fileName);

//       // Show success message
//       Swal.fire({
//         title: "Export Complete",
//         html: `<div class="flex flex-col items-center">
//                 <div class="w-16 h-16 bg-teal-500 rounded-full flex items-center justify-center mb-4">
//                   <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
//                   </svg>
//                 </div>
//                 <p class="text-teal-400">Your export has been downloaded successfully.</p>
//                 <p class="text-sm text-slate-400 mt-2">Filename: ${fileName}</p>
//               </div>`,
//         icon: false,
//         background: "#1E293B",
//         customClass: {
//           popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
//           title: "text-teal-300",
//           confirmButton:
//             "bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded-lg",
//         },
//       });
//     } catch (error) {
//       console.error("Export error:", error);

//       // Show error message
//       Swal.fire({
//         title: "Export Error",
//         text: "There was an error exporting your data. Please try again.",
//         icon: "error",
//         background: "#1E293B",
//         customClass: {
//           popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
//           title: "text-red-400",
//           confirmButton:
//             "bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded-lg",
//         },
//       });
//     }
//   };

//   // Function to calculate date ranges based on period selection
//   const getDateRangeForPeriod = (period) => {
//     const today = new Date();
//     today.setHours(0, 0, 0, 0);

//     let startDate = null;
//     let endDate = new Date(today);
//     endDate.setHours(23, 59, 59, 999);

//     switch (period) {
//       case "today":
//         startDate = new Date(today);
//         break;
//       case "week":
//         startDate = new Date(today);
//         startDate.setDate(today.getDate() - 7);
//         break;
//       case "3weeks":
//         startDate = new Date(today);
//         startDate.setDate(today.getDate() - 21);
//         break;
//       case "month":
//         startDate = new Date(today);
//         startDate.setMonth(today.getMonth() - 1);
//         break;
//       case "3months":
//         startDate = new Date(today);
//         startDate.setMonth(today.getMonth() - 3);
//         break;
//       case "6months":
//         startDate = new Date(today);
//         startDate.setMonth(today.getMonth() - 6);
//         break;
//       case "9months":
//         startDate = new Date(today);
//         startDate.setMonth(today.getMonth() - 9);
//         break;
//       case "year":
//         startDate = new Date(today);
//         startDate.setFullYear(today.getFullYear() - 1);
//         break;
//       case "all":
//       default:
//         startDate = null;
//         endDate = null;
//     }

//     return { startDate, endDate, periodName: period };
//   };

//   // Main function to show export dialog
//   const handleExportToExcel = () => {
//     Swal.fire({
//       title: `<span class="text-teal-400">Export Transactions</span>`,
//       html: `
//       <div class="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6 rounded-xl shadow-lg border border-slate-700">
//         <div class="mb-6">
//           <h3 class="text-teal-300 font-medium mb-3 flex items-center">
//             <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
//             </svg>
//             Select Time Period
//           </h3>

//           <select
//             id="exportPeriod"
//             class="w-full bg-slate-700 text-teal-300 border border-slate-600 rounded-lg p-3 outline-none focus:ring-2 focus:ring-teal-500 transition-all">
//             <option value="all" class="bg-slate-700 text-teal-300">All Transactions</option>
//             <option value="today" class="bg-slate-700 text-teal-300">Today Only</option>
//             <option value="week" class="bg-slate-700 text-teal-300">Last 7 Days</option>
//             <option value="3weeks" class="bg-slate-700 text-teal-300">Last 3 Weeks</option>
//             <option value="month" class="bg-slate-700 text-teal-300">Last Month</option>
//             <option value="3months" class="bg-slate-700 text-teal-300">Last 3 Months</option>
//             <option value="6months" class="bg-slate-700 text-teal-300">Last 6 Months</option>
//             <option value="9months" class="bg-slate-700 text-teal-300">Last 9 Months</option>
//             <option value="year" class="bg-slate-700 text-teal-300">Last Year</option>
//             <option value="custom" class="bg-slate-700 text-teal-300">Custom Date Range</option>
//           </select>
//         </div>

//         <div id="customDateFields" style="display: none;" class="animate-fade-in">
//           <h3 class="text-teal-300 font-medium mb-3 flex items-center">
//             <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
//             </svg>
//             Select Custom Dates
//           </h3>
//           <div class="grid grid-cols-2 gap-4 mb-4">
//             <div>
//               <label class="block text-teal-300 text-sm mb-2">Start Date</label>
//               <input
//                 type="date"
//                 id="customStartDate"
//                 class="w-full px-4 py-3 rounded-lg text-teal-400 bg-slate-700 border border-slate-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
//               />
//             </div>
//             <div>
//               <label class="block text-teal-300 text-sm mb-2">End Date</label>
//               <input
//                 type="date"
//                 id="customEndDate"
//                 class="w-full px-4 py-3 rounded-lg text-teal-400 bg-slate-700 border border-slate-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
//               />
//             </div>
//           </div>
//         </div>

//         <div class="mt-6 bg-slate-700/30 rounded-lg p-4 border-l-4 border-teal-500">
//           <p class="text-slate-300 text-sm flex items-start">
//             <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-teal-400 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
//             </svg>
//             <span>Data will be fetched based on your selected date range and exported as an Excel file with formatting.</span>
//           </p>
//         </div>

//         <div class="mt-4">
//           <p class="text-slate-400 text-xs">Society: ${
//             activeSociety?.name || "Unknown"
//           }</p>
//         </div>
//       </div>

//       <style>
//         .animate-fade-in {
//           animation: fadeIn 0.3s ease-in-out;
//         }
//         @keyframes fadeIn {
//           from { opacity: 0; transform: translateY(-10px); }
//           to { opacity: 1; transform: translateY(0); }
//         }
//         .swal2-popup {
//           border-radius: 1rem !important;
//         }
//       </style>
//       `,
//       confirmButtonText:
//         '<span class="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" /></svg> Export</span>',
//       showCancelButton: true,
//       cancelButtonText: "Cancel",
//       focusConfirm: false,
//       background: "#1E293B",
//       customClass: {
//         popup:
//           "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-xl",
//         title: "text-teal-300",
//         confirmButton:
//           "bg-teal-600 hover:bg-teal-700 text-white px-6 py-3 rounded-lg shadow-md transition-all duration-200 flex items-center",
//         cancelButton:
//           "bg-slate-600 hover:bg-slate-700 text-white px-6 py-3 rounded-lg shadow-md transition-all duration-200",
//         content: "mt-2",
//       },
//       didOpen: () => {
//         // Initialize event listener for dropdown change
//         const exportPeriodElement = document.getElementById("exportPeriod");
//         const customDateFields = document.getElementById("customDateFields");

//         if (exportPeriodElement) {
//           exportPeriodElement.addEventListener("change", function () {
//             if (this.value === "custom") {
//               customDateFields.style.display = "block";
//             } else {
//               customDateFields.style.display = "none";
//             }
//           });
//         }
//       },
//       preConfirm: () => {
//         const exportPeriod = document.getElementById("exportPeriod").value;

//         // If custom date range is selected, validate and return both dates
//         if (exportPeriod === "custom") {
//           const startDate = document.getElementById("customStartDate").value;
//           const endDate = document.getElementById("customEndDate").value;

//           if (!startDate || !endDate) {
//             Swal.showValidationMessage(
//               "Please select both start and end dates"
//             );
//             return false;
//           }

//           // Validate that end date is not before start date
//           if (new Date(endDate) < new Date(startDate)) {
//             Swal.showValidationMessage("End date cannot be before start date");
//             return false;
//           }

//           return {
//             period: "custom",
//             startDate,
//             endDate,
//           };
//         }

//         return {
//           period: exportPeriod,
//         };
//       },
//     }).then((result) => {
//       if (result.isConfirmed) {
//         const exportData = result.value;

//         if (exportData.period === "custom") {
//           // Handle custom date range
//           exportTransactionsWithDateRange(
//             exportData.startDate,
//             exportData.endDate,
//             "custom"
//           );
//         } else {
//           // Handle predefined period
//           const { startDate, endDate, periodName } = getDateRangeForPeriod(
//             exportData.period
//           );
//           exportTransactionsWithDateRange(startDate, endDate, periodName);
//         }
//       }
//     });
//   };

//   return (
//     <button
//       onClick={handleExportToExcel}
//       className="px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-teal-600 transition flex items-center gap-2 shadow-md"
//     >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         className="h-5 w-5"
//         fill="none"
//         viewBox="0 0 24 24"
//         stroke="currentColor"
//       >
//         <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           strokeWidth={2}
//           d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
//         />
//       </svg>
//       Export to Excel
//     </button>
//   );
// };

// export default ExportExcel;

import React, { useState } from "react";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

const ExportExcel = ({
  activeSociety,
  filteredTransactions,
  filters,
  dispatch,
  fetchTransactions,
  sortOrder,
}) => {
  // Function to process and export transaction data
  const processAndExportData = (transactions) => {
    if (!Array.isArray(transactions) || transactions.length === 0) {
      Swal.fire({
        title: "No Data",
        text: "No transactions available to export.",
        icon: "warning",
        background: "#1E293B",
        customClass: {
          popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
          title: "text-amber-400",
        },
      });
      return;
    }

    let totalIncome = 0;
    let totalExpense = 0;

    const dataToExport = transactions.map((transaction) => {
      const amount = transaction.amount || 0;

      if (
        transaction.transaction_direction === "in" ||
        transaction.transaction_direction === "Income"
      ) {
        totalIncome += amount;
      } else if (
        transaction.transaction_direction === "out" ||
        transaction.transaction_direction === "Expense"
      ) {
        totalExpense += amount;
      }

      return {
        Date: new Date(transaction.transaction_date).toLocaleDateString(),
        "Receipt No.": transaction.receipt_no || "N/A",
        "Customer/Employee":
          transaction.customer_id?.name ||
          (transaction.customer_info && transaction.customer_info[0]?.name) ||
          transaction.employee_id?.name ||
          transaction.user_id?.investorData?.name ||
          "N/A",
        Society: activeSociety?.name || "N/A",
        "Plot Number":
          transaction.plot_id?.plot_number ||
          (transaction.plot_info && transaction.plot_info[0]?.plot_number) ||
          "No Plot",
        "Transaction Type": transaction.transaction_type || "N/A",
        Direction: transaction.transaction_direction || "N/A",
        Method: transaction.payment_method || "N/A",
        Amount: amount,
        Status: transaction.status || "N/A",
        Description: transaction.description || "N/A",
      };
    });

    // Add summary rows
    dataToExport.push({
      Date: "",
      "Receipt No.": "",
      "Customer/Employee": "",
      Society: "",
      "Plot Number": "",
      "Transaction Type": "",
      Direction: "Total Income",
      Method: "",
      Amount: totalIncome,
      Status: "",
      Description: "",
    });

    dataToExport.push({
      Date: "",
      "Receipt No.": "",
      "Customer/Employee": "",
      Society: "",
      "Plot Number": "",
      "Transaction Type": "",
      Direction: "Total Expense",
      Method: "",
      Amount: totalExpense,
      Status: "",
      Description: "",
    });

    dataToExport.push({
      Date: "",
      "Receipt No.": "",
      "Customer/Employee": "",
      Society: "",
      "Plot Number": "",
      "Transaction Type": "",
      Direction: "Net Balance",
      Method: "",
      Amount: totalIncome - totalExpense,
      Status: "",
      Description: "",
    });

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Get range of worksheet
    const range = XLSX.utils.decode_range(worksheet["!ref"]);

    // Create styles
    const styles = {
      headerStyle: {
        fill: { fgColor: { rgb: "20485B" } }, // Dark teal
        font: { bold: true, color: { rgb: "FFFFFF" } },
        alignment: { horizontal: "center", vertical: "center" },
        border: {
          top: { style: "thin", color: { rgb: "FFFFFF" } },
          bottom: { style: "thin", color: { rgb: "FFFFFF" } },
          left: { style: "thin", color: { rgb: "FFFFFF" } },
          right: { style: "thin", color: { rgb: "FFFFFF" } },
        },
      },
      alternateRowStyle: {
        fill: { fgColor: { rgb: "F0FDFA" } }, // Light teal
      },
      incomeStyle: {
        font: { color: { rgb: "008000" } }, // Green
      },
      expenseStyle: {
        font: { color: { rgb: "FF0000" } }, // Red
      },
      totalRowStyle: {
        fill: { fgColor: { rgb: "134E4A" } }, // Darker teal
        font: { bold: true, color: { rgb: "FFFFFF" } },
        alignment: { horizontal: "center" },
      },
      netBalanceStyle: {
        fill: { fgColor: { rgb: "0F766E" } }, // Dark teal
        font: { bold: true, color: { rgb: "FFFFFF" }, sz: 12 },
        alignment: { horizontal: "center" },
      },
    };

    // Apply styles
    for (let R = 0; R <= range.e.r; R++) {
      for (let C = 0; C <= range.e.c; C++) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });

        // Initialize cell if needed
        if (!worksheet[cellRef]) {
          worksheet[cellRef] = { v: "", t: "s" };
        }

        // Add style property
        if (!worksheet[cellRef].s) {
          worksheet[cellRef].s = {};
        }

        // Header row
        if (R === 0) {
          worksheet[cellRef].s = styles.headerStyle;
        }
        // Alternate row shading
        else if (R % 2 === 0 && R < range.e.r - 2) {
          worksheet[cellRef].s = styles.alternateRowStyle;
        }
        // Total income row
        else if (R === range.e.r - 2) {
          worksheet[cellRef].s = styles.totalRowStyle;

          // Format amount column
          if (C === Object.keys(dataToExport[0]).indexOf("Amount")) {
            worksheet[cellRef].s = {
              ...worksheet[cellRef].s,
              ...styles.incomeStyle,
            };
          }
        }
        // Total expense row
        else if (R === range.e.r - 1) {
          worksheet[cellRef].s = styles.totalRowStyle;

          // Format amount column
          if (C === Object.keys(dataToExport[0]).indexOf("Amount")) {
            worksheet[cellRef].s = {
              ...worksheet[cellRef].s,
              ...styles.expenseStyle,
            };
          }
        }
        // Net balance row
        else if (R === range.e.r) {
          worksheet[cellRef].s = styles.netBalanceStyle;
        }

        // Amount column styling for regular rows
        if (
          C === Object.keys(dataToExport[0]).indexOf("Amount") &&
          worksheet[cellRef].v &&
          R < range.e.r - 2
        ) {
          const transaction = transactions[R - 1];
          if (transaction) {
            if (
              transaction.transaction_direction === "in" ||
              transaction.transaction_direction === "Income"
            ) {
              worksheet[cellRef].s = {
                ...worksheet[cellRef].s,
                ...styles.incomeStyle,
              };
            } else if (
              transaction.transaction_direction === "out" ||
              transaction.transaction_direction === "Expense"
            ) {
              worksheet[cellRef].s = {
                ...worksheet[cellRef].s,
                ...styles.expenseStyle,
              };
            }
          }
        }
      }
    }

    // Set column widths for better readability
    worksheet["!cols"] = [
      { wpx: 100 }, // Date
      { wpx: 120 }, // Receipt No
      { wpx: 180 }, // Customer/Employee
      { wpx: 150 }, // Society
      { wpx: 100 }, // Plot Number
      { wpx: 150 }, // Transaction Type
      { wpx: 100 }, // Direction
      { wpx: 100 }, // Method
      { wpx: 100 }, // Amount
      { wpx: 100 }, // Status
      { wpx: 200 }, // Description
    ];

    // Create workbook and add worksheet
    const workbook = {
      SheetNames: ["Transactions"],
      Sheets: {
        Transactions: worksheet,
      },
    };

    return workbook;
  };

  // Function to handle the actual export with specific date range
  const exportTransactionsWithDateRange = async (
    startDate,
    endDate,
    periodName
  ) => {
    try {
      // Show loading state
      Swal.fire({
        title: "Preparing Export",
        html: `<div class="flex flex-col items-center">
                <div class="w-16 h-16 border-t-4 border-teal-500 border-solid rounded-full animate-spin mb-4"></div>
                <p class="text-teal-400">Gathering transaction data...</p>
              </div>`,
        allowOutsideClick: false,
        showConfirmButton: false,
        background: "#1E293B",
        customClass: {
          popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
          title: "text-teal-300",
        },
      });

      // Format dates for API call if provided
      let formattedStartDate = null;
      let formattedEndDate = null;

      if (startDate && endDate) {
        formattedStartDate = new Date(startDate);
        formattedStartDate.setHours(0, 0, 0, 0);

        formattedEndDate = new Date(endDate);
        formattedEndDate.setHours(23, 59, 59, 999);
      }

      // Fetch filtered data based on date range
      const response = await dispatch(
        fetchTransactions({
          societyId: activeSociety?._id,
          description: filters.description,
          startDate: formattedStartDate
            ? formattedStartDate.toISOString()
            : undefined,
          endDate: formattedEndDate
            ? formattedEndDate.toISOString()
            : undefined,
          sortOrder,
        })
      ).unwrap();

      // Get transactions data
      let transactions = [];
      if (
        response?.data?.transactions &&
        Array.isArray(response.data.transactions)
      ) {
        transactions = response.data.transactions;
      }

      // Process and export data
      const workbook = processAndExportData(transactions);

      if (!workbook) {
        return;
      }

      // Generate filename with date range info
      const societyName = activeSociety
        ? activeSociety.name.replace(/\s+/g, "_")
        : "Society";
      let fileName = "";

      if (periodName === "custom" && startDate && endDate) {
        // Format dates for filename
        const startDateFormatted = new Date(startDate)
          .toISOString()
          .slice(0, 10);
        const endDateFormatted = new Date(endDate).toISOString().slice(0, 10);
        fileName = `${societyName}_transactions_${startDateFormatted}_to_${endDateFormatted}.xlsx`;
      } else {
        // Use period name in filename
        const today = new Date().toISOString().slice(0, 10);
        fileName = `${societyName}_transactions_${periodName}_${today}.xlsx`;
      }

      // Write file using XLSX
      XLSX.writeFile(workbook, fileName);

      // Show success message
      Swal.fire({
        title: "Export Complete",
        html: `<div class="flex flex-col items-center">
                <div class="w-16 h-16 bg-teal-500 rounded-full flex items-center justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p class="text-teal-400">Your export has been downloaded successfully.</p>
                <p class="text-sm text-slate-400 mt-2">Filename: ${fileName}</p>
              </div>`,
        icon: false,
        background: "#1E293B",
        customClass: {
          popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
          title: "text-teal-300",
          confirmButton:
            "bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded-lg",
        },
      });
    } catch (error) {
      console.error("Export error:", error);

      // Show error message
      Swal.fire({
        title: "Export Error",
        text: "There was an error exporting your data. Please try again.",
        icon: "error",
        background: "#1E293B",
        customClass: {
          popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
          title: "text-red-400",
          confirmButton:
            "bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded-lg",
        },
      });
    }
  };

  // Function to calculate date ranges based on period selection
  const getDateRangeForPeriod = (period) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let startDate = null;
    let endDate = new Date(today);
    endDate.setHours(23, 59, 59, 999);

    switch (period) {
      case "today":
        startDate = new Date(today);
        break;
      case "week":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7);
        break;
      case "3weeks":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 21);
        break;
      case "month":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 1);
        break;
      case "3months":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 3);
        break;
      case "6months":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 6);
        break;
      case "9months":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 9);
        break;
      case "year":
        startDate = new Date(today);
        startDate.setFullYear(today.getFullYear() - 1);
        break;
      case "all":
      default:
        startDate = null;
        endDate = null;
    }

    return { startDate, endDate, periodName: period };
  };

  // Main function to show export dialog with chip buttons
  const handleExportToExcel = () => {
    // Period options with icons
    const periodOptions = [
      {
        value: "all",
        label: "All Transactions",
        icon: "M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10",
      },
      {
        value: "today",
        label: "Today Only",
        icon: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z",
      },
      {
        value: "week",
        label: "Last 7 Days",
        icon: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z",
      },
      {
        value: "3weeks",
        label: "Last 3 Weeks",
        icon: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z",
      },
      {
        value: "month",
        label: "Last Month",
        icon: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z",
      },
      {
        value: "3months",
        label: "Last 3 Months",
        icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4",
      },
      {
        value: "6months",
        label: "Last 6 Months",
        icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4",
      },
      {
        value: "9months",
        label: "Last 9 Months",
        icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2",
      },
      {
        value: "year",
        label: "Last Year",
        icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2",
      },
      {
        value: "custom",
        label: "Custom Date Range",
        icon: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z",
      },
    ];

    // Create chip buttons HTML
    const createChipButtons = () => {
      return `
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 my-4">
          ${periodOptions
            .map(
              (option) => `
            <button 
              data-value="${option.value}" 
              class="chip-button flex items-center justify-center gap-2 py-3 px-4 rounded-lg transition-all duration-200 bg-slate-700 hover:bg-slate-600 text-teal-300 border border-slate-600 hover:border-teal-500 group overflow-hidden relative">
              <span class="chip-glow absolute inset-0 bg-teal-500 opacity-0 group-hover:opacity-10 transition-opacity duration-300"></span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-teal-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="${option.icon}" />
              </svg>
              <span class="text-sm font-medium">${option.label}</span>
            </button>
          `
            )
            .join("")}
        </div>
      `;
    };

    // Create custom date fields
    const createCustomDateFields = () => {
      return `
        <div id="customDateFields" style="display: none;" class="animate-fade-in mt-6 p-4 bg-slate-800 rounded-lg border border-slate-700">
          <h3 class="text-teal-300 font-medium mb-3 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            Select Custom Dates
          </h3>
          <div class="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label class="block text-teal-300 text-sm mb-2">Start Date</label>
              <input 
                type="date" 
                id="customStartDate" 
                class="w-full px-4 py-3 rounded-lg text-teal-400 bg-slate-700 border border-slate-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
            </div>
            <div>
              <label class="block text-teal-300 text-sm mb-2">End Date</label>
              <input 
                type="date" 
                id="customEndDate" 
                class="w-full px-4 py-3 rounded-lg text-teal-400 bg-slate-700 border border-slate-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
            </div>
          </div>
        </div>
      `;
    };

    Swal.fire({
      title: `<span class="text-teal-400">Export Transactions</span>`,
      html: `
      <div class="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6 rounded-xl shadow-lg border border-slate-700">
        <div class="mb-4">
          <h3 class="text-teal-300 font-medium mb-3 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            Select Time Period
          </h3>
          
          <div id="selectedPeriodDisplay" class="flex items-center gap-2 py-2 px-3 bg-slate-700/50 rounded-lg border border-slate-600 mb-3 hidden">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-teal-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
            </svg>
            <span id="selectedPeriodLabel" class="text-teal-300 font-medium">All Transactions</span>
            <button id="changePeriodBtn" class="ml-auto text-xs text-slate-400 hover:text-teal-300 transition-colors">
              Change
            </button>
          </div>
          
          <div id="chipButtonsContainer">
            ${createChipButtons()}
          </div>
        </div>
        
        ${createCustomDateFields()}
        
        <div class="mt-6 bg-slate-700/30 rounded-lg p-4 border-l-4 border-teal-500">
          <p class="text-slate-300 text-sm flex items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-teal-400 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>Data will be fetched based on your selected time period and exported as an Excel file with formatting.</span>
          </p>
        </div>
        
        <div class="mt-4">
          <p class="text-slate-400 text-xs">Society: ${
            activeSociety?.name || "Unknown"
          }</p>
        </div>
      </div>
      
      <style>
        .animate-fade-in {
          animation: fadeIn 0.3s ease-in-out;
        }
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .chip-button.selected {
          background-color: #14b8a6;
          color: white;
          border-color: #14b8a6;
          box-shadow: 0 0 0 2px rgba(20, 184, 166, 0.3);
          transform: translateY(-2px);
        }
        .chip-button.selected svg {
          color: white;
        }
        .swal2-popup {
          border-radius: 1rem !important;
        }
        .pulse-animation {
          animation: pulse 1.5s infinite;
        }
        @keyframes pulse {
          0% { box-shadow: 0 0 0 0 rgba(20, 184, 166, 0.7); }
          70% { box-shadow: 0 0 0 10px rgba(20, 184, 166, 0); }
          100% { box-shadow: 0 0 0 0 rgba(20, 184, 166, 0); }
        }
      </style>
      `,
      confirmButtonText:
        '<span class="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" /></svg> Export</span>',
      showCancelButton: true,
      cancelButtonText: "Cancel",
      focusConfirm: false,
      background: "#1E293B",
      customClass: {
        popup:
          "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-xl",
        title: "text-teal-300",
        confirmButton:
          "bg-teal-600 hover:bg-teal-700 text-white px-6 py-3 rounded-lg shadow-md transition-all duration-200 flex items-center pulse-animation",
        cancelButton:
          "bg-slate-600 hover:bg-slate-700 text-white px-6 py-3 rounded-lg shadow-md transition-all duration-200",
        content: "mt-2",
      },
      didOpen: () => {
        const chipButtons = document.querySelectorAll(".chip-button");
        const customDateFields = document.getElementById("customDateFields");
        const selectedPeriodDisplay = document.getElementById(
          "selectedPeriodDisplay"
        );
        const selectedPeriodLabel = document.getElementById(
          "selectedPeriodLabel"
        );
        const chipButtonsContainer = document.getElementById(
          "chipButtonsContainer"
        );
        const changePeriodBtn = document.getElementById("changePeriodBtn");

        let selectedPeriod = "all";

        // Set click handlers for chip buttons
        // Fix for the event listener section:
        chipButtons.forEach((button) => {
          button.addEventListener("click", function () {
            const value = this.dataset.value;

            // Remove selected class from all buttons
            chipButtons.forEach((btn) => btn.classList.remove("selected"));

            // Add selected class to clicked button
            this.classList.add("selected");

            // Update selected period
            selectedPeriod = value;

            // Toggle custom date fields visibility
            if (value === "custom") {
              customDateFields.style.display = "block";
            } else {
              customDateFields.style.display = "none";
            }

            // Update the selected period display
            const selectedOption = periodOptions.find(
              (option) => option.value === value
            );
            if (selectedOption) {
              selectedPeriodLabel.textContent = selectedOption.label;
            }

            // Show the selected period display and hide the chip buttons
            selectedPeriodDisplay.classList.remove("hidden");
            chipButtonsContainer.classList.add("hidden");
          });
        });

        // Set change period button handler
        if (changePeriodBtn) {
          changePeriodBtn.addEventListener("click", function () {
            selectedPeriodDisplay.classList.add("hidden");
            chipButtonsContainer.classList.remove("hidden");
          });
        }

        // Select "All Transactions" by default
        const defaultButton = document.querySelector(
          '.chip-button[data-value="all"]'
        );
        if (defaultButton) {
          defaultButton.classList.add("selected");
        }
      },
      preConfirm: () => {
        // Get selected period
        const selectedButton = document.querySelector(".chip-button.selected");
        if (!selectedButton) {
          Swal.showValidationMessage("Please select a time period");
          return false;
        }

        const selectedPeriod = selectedButton.dataset.value;

        // Check if custom dates are needed and validate them
        if (selectedPeriod === "custom") {
          const startDateInput = document.getElementById("customStartDate");
          const endDateInput = document.getElementById("customEndDate");

          if (!startDateInput.value) {
            Swal.showValidationMessage("Please select a start date");
            return false;
          }

          if (!endDateInput.value) {
            Swal.showValidationMessage("Please select an end date");
            return false;
          }

          const startDate = new Date(startDateInput.value);
          const endDate = new Date(endDateInput.value);

          if (startDate > endDate) {
            Swal.showValidationMessage("Start date cannot be after end date");
            return false;
          }

          return {
            period: selectedPeriod,
            startDate: startDateInput.value,
            endDate: endDateInput.value,
          };
        }

        return { period: selectedPeriod };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { period, startDate, endDate } = result.value;

        if (period === "custom" && startDate && endDate) {
          // Handle custom date range
          exportTransactionsWithDateRange(startDate, endDate, "custom");
        } else {
          // Handle predefined periods
          const { startDate, endDate, periodName } =
            getDateRangeForPeriod(period);
          exportTransactionsWithDateRange(startDate, endDate, periodName);
        }
      }
    });
  };

  return (
    <button
      onClick={handleExportToExcel}
      className="group px-4 py-2 rounded-lg bg-gradient-to-r from-teal-600 to-teal-500 hover:from-teal-500 hover:to-teal-400 text-white font-medium transition-all duration-200 shadow-md hover:shadow-lg flex items-center gap-2"
      title="Export Transactions to Excel"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-teal-100 group-hover:text-white transition-colors"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
      Export to Excel
    </button>
  );
};

export default ExportExcel;
