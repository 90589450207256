import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllBookings,
  deleteBooking,
  updateBooking
} from '../store/slices/bookingSlice'
import { fetchSocieties } from '../store/slices/societySlice'
import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'
import {
  FileText,
  Printer,
  MapPin,
  ChevronDown,
  Filter,
  Search,
  Trash2,
  Edit,
  X,
  Lock,
  ChevronRight,
  ChevronLeft,
  Download
} from 'lucide-react'
import printJS from 'print-js'
import Logo from '../assets/Logo/Logo.png'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  formatExportData,
  exportToExcel,
  filterBookingsByDateRange,
  getDateRanges
} from './formatExportData'
import Swal from 'sweetalert2'

const FullPaymentBookings = () => {
  const dispatch = useDispatch()
  const { bookings } = useSelector(state => state.booking)
  console.log('Redux bookings:', bookings)

  const { activeSociety, theme, gardient } = useSidebarContext()
  const { societies = [] } = useSelector(state => state.societies)
  const activeUser = useSelector(state => state.auth.activeUser)

  // State management
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOption, setSortOption] = useState('latest')
  const [currentPage, setCurrentPage] = useState(1)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [editFormData, setEditFormData] = useState({})
  const [filteredUsers, setFilteredUsers] = useState([])
  const [plotSearchTerm, setPlotSearchTerm] = useState('')
  const rowsPerPage = 8
  const isEmployee = activeUser?.role === 'Employee'

  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [exportStartDate, setExportStartDate] = useState(null)
  const [exportEndDate, setExportEndDate] = useState(null)
  const [exportPreset, setExportPreset] = useState('custom')
  const [exportType, setExportType] = useState('filtered')

  useEffect(() => {
    dispatch(getAllBookings())
    dispatch(fetchSocieties())
  }, [dispatch])

  useEffect(() => {
    if (activeSociety?.users) {
      setFilteredUsers(activeSociety.users)
    }
  }, [activeSociety])

  const filteredAndSortedBookings = useMemo(() => {
    // First filter for full payment bookings in active society
    let result = bookings.filter(
      booking =>
        booking.payment_mode === 'Full' &&
        booking.plot_id?.society_id === activeSociety?._id
    )

    // Search filtering
    if (searchTerm) {
      result = result.filter(booking => {
        const bookingNumber =
          booking.booking_number?.toString().toLowerCase() || ''
        const username =
          booking?.customer_id?._id?.username?.toLowerCase() || 'unknown'
        return (
          bookingNumber.includes(searchTerm.toLowerCase()) ||
          username.includes(searchTerm.toLowerCase())
        )
      })
    }

    // Plot number filtering
    if (plotSearchTerm) {
      result = result.filter(booking => {
        const plotNumber =
          booking.plot_id?.plot_number?.toString().toLowerCase() || ''
        return plotNumber.includes(plotSearchTerm.toLowerCase())
      })
    }

    // Sorting
    return result.sort((a, b) => {
      switch (sortOption) {
        case 'amount-asc':
          return a.total_amount - b.total_amount
        case 'amount-desc':
          return b.total_amount - a.total_amount
        case 'latest':
        default:
          return new Date(b.createdAt) - new Date(a.createdAt)
      }
    })
  }, [bookings, activeSociety, searchTerm, plotSearchTerm, sortOption])

  // Calculate total pages
  const totalPages = Math.ceil(filteredAndSortedBookings.length / rowsPerPage)

  // Get current page's bookings
  const paginatedBookings = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return filteredAndSortedBookings.slice(startIndex, endIndex)
  }, [filteredAndSortedBookings, currentPage, rowsPerPage])

  // Navigation functions
  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(1, prev - 1))
  }

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(totalPages, prev + 1))
  }
  const handlePrint = booking => {
    const customer = booking.customer_id
    const societyDetails =
      booking.plot_id && booking.plot_id.society_id
        ? getSocietyDetails(booking.plot_id.society_id)
        : {
            name: 'Housing City',
            location: 'Main Branch, City Center',
            society_image: Logo
          }

    if (!customer || !customer.contactInfo) {
      console.error('Customer details are missing.')
      return
    }

    const printContent = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Booking Confirmation</title>
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
        
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }
        
        body {
            font-family: 'Inter', Arial, sans-serif;
            line-height: 1.6;
            color: #000000;
            margin: 0;
            // padding: 20mm;
            background-color: #ffffff;
        }
        
        .container {
            max-width: 210mm;  /* A4 width */
            margin: 0 auto;
            padding: 0;
            background-color: #ffffff;
        }
        
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #000000;
            // padding-bottom: 10px;
            // margin-bottom: 20px;
        }
        
        .header-logo {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        
        .header-logo img {
            width: 60px;
            height: auto;
            border-radius: 4px;
        }
        
        .header-logo-text h1 {
            margin: 0;
            font-size: 24px;
            font-weight: 700;
            color: #000;
        }
        
        .header-logo-text h4 {
            margin: 5px 0 0;
            font-size: 16px;
            color: #333;
        }
        
        .header-booking-info {
            text-align: right;
        }
        
        .title {
            text-align: center;
            font-size: 18px;
            margin-bottom: 0px;
            font-weight: bold;
        }
        .title1 {
            text-align: center;
            font-size: 12px;
            margin-bottom: 20px;
            font-weight: bold;
            border-bottom: 1px solid #000;
            padding-bottom: 5px;
        }
        
        .details-section {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
        }
        
        .details-column {
            flex: 1;
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;
        }
        
        .details-column h3 {
            margin: 0 0 10px;
            font-size: 16px;
            border-bottom: 1px solid #000;
            padding-bottom: 5px;
        }
        
        .details-item {
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 5px;
            margin-bottom: 5px;
        }
        
        .waris-details {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }
        
        .waris-column {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        
        .signature-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2px;
            margin-top: 60px;
        }
        
        .signature-column {
            text-align: center;
            width: 45%;
            border-top: 1px solid #000;
            padding-top: 10px;
        }
        
        .terms-section {
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;
        }
        
        .terms-section h3 {
            margin: 0 0 10px;
            font-size: 18px;
            border-bottom: 1px solid #000;
            padding-bottom: 5px;
        }
        
        .terms-section ol {
            padding-left: 15px;
            line-height: 1.5;
            font-size: 14px;
            margin: 0;
        }
        
        .footer {
            text-align: center;
            font-size: 12px;
            margin-top: 10px;
            border-top: 1px solid #000;
            padding-top: 10px;
        }
        
        @media print {
            body {
                padding: 0;
                margin: 0;
            }
            .container {
                width: 100%;
                max-width: none;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <!-- Header -->
        <div class="header">
            <div class="header-logo">
                ${
                  societyDetails.image
                    ? `<img src="${societyDetails.image}" alt="Society Logo" />`
                    : ''
                }
                <div class="header-logo-text">
                    <h1>${societyDetails.name}</h1>
                    <h4>${societyDetails.location}</h4>
                </div>
            </div>
            <div class="header-booking-info">
            <p><strong>Booking Receipt no.:</strong> ${booking.receipt_no}</p>
                <p><strong>Booking Number:</strong> ${
                  booking.booking_number
                }</p>
                <p><strong>Booking Date:</strong> ${new Date(
                  booking.booking_date
                ).toLocaleDateString()}</p>
            </div>
        </div>

        <!-- Title -->
        <h2 class="title">Official Booking Confirmation</h2>
        <p class="title1">Booked Plot Via Installment Plan </p> 
        <!-- Customer and Property Details -->
        <div class="details-section">
            <!-- Customer Details -->
            <div class="details-column">
                <h3>Customer Details</h3>
                <p class="details-item"><strong>Name:</strong> ${
                  customer.name || 'Unknown'
                }</p>
                <p class="details-item"><strong>Father Name:</strong> ${
                  customer.contactInfo?.father_name || 'N/A'
                }</p>
                <p class="details-item"><strong>Phone:</strong> ${
                  customer.contactInfo?.phone || 'N/A'
                }</p>
                <p class="details-item"><strong>Email:</strong> ${
                  customer.contactInfo?.email || 'N/A'
                }</p>
                <p class="details-item"><strong>CNIC:</strong> ${
                  customer.contactInfo?.cnic || 'N/A'
                }</p>
                <p class="details-item"><strong>Address:</strong> ${
                  customer.contactInfo?.address || 'N/A'
                }</p>
            </div>

            <!-- Property Details -->
            <div class="details-column">
                <h3>Property Details</h3>
                <p class="details-item"><strong>Plot Number:</strong> ${
                  booking.plot_id?.plot_number || 'N/A'
                } - ${booking.plot_id?.plot_type || 'N/A'} - ${
      booking.plot_id?.category || 'N/A'
    }</p>
                
                <p class="details-item"><strong>Plot size (Marla):</strong> ${
                  booking.plot_id?.size || 'N/A'
                } Marla</p>
                <p class="details-item"><strong>Total Price:</strong> ${formatCurrency(
                  booking.total_amount
                )}</p>
                <p class="details-item"><strong>Total Paid Payment:</strong> ${formatCurrency(
                  booking.total_amount || 0
                )}</p>
                
            </div>
        </div>

        <!-- Waris Details -->
        <div class="details-section">
            <div class="details-column">
                <h3>Waris Details</h3>
                <div class="waris-details">
                    <div class="waris-column">
                        <p class="details-item">
                            <strong>Waris Name:</strong> ${
                              customer.contactInfo?.waris_name || 'Unknown'
                            }
                        </p>
                        <p class="details-item">
                            <strong>Waris Father Name:</strong> ${
                              customer.contactInfo?.waris_father_name || 'N/A'
                            }
                        </p>
                        <p class="details-item">
                            <strong>Waris Phone no.:</strong> ${
                              customer.contactInfo?.waris_phone || 'N/A'
                            }
                        </p>
                    </div>
                    <div class="waris-column">
                        <p class="details-item">
                            <strong>Waris relation:</strong> ${
                              customer.contactInfo?.waris_relation || 'N/A'
                            }
                        </p>
                        <p class="details-item">
                            <strong>Waris CNIC:</strong> ${
                              customer.contactInfo?.waris_cnic || 'N/A'
                            }
                        </p>
                        <p class="details-item">
                            <strong>Waris Address:</strong> ${
                              customer.contactInfo?.waris_address || 'N/A'
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Signature Section -->
        <div class="signature-section">
            <div class="signature-column">
                <p>Customer Signature</p>
            </div>
            <div class="signature-column">
                <p>Authorized Signature</p>
            </div>
        </div>

        <!-- Terms and Conditions -->
        <div class="terms-section">
            <h3>Terms and Conditions</h3>
            <ol>
                <li>This booking is subject to final approval by Housing City management.</li>
                <li>Payment plans are non-transferable and non-negotiable.</li>
                <li>Any changes to the plot allocation require written consent from Housing City.</li>
                <li>Default in payment may result in cancellation of booking.</li>
                <li>All legal disputes will be settled in the jurisdiction of the city where the property is located.</li>
            </ol>
        </div>

        <!-- Footer -->
        <div class="footer">
            <p>This is a computer-generated booking slip and does not require a physical signature.</p>
            <p>© ${new Date().getFullYear()} ${
      societyDetails.name
    }. All rights reserved.</p>
        </div>
    </div>
</body>
</html>
`

    printJS({
      printable: printContent,
      type: 'raw-html',
      style: `
    @page { size: A4; margin: 10mm; }
    body { margin: 0; font-family: 'Inter', Arial, sans-serif;  -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important; }
  `,
      documentTitle: 'Al-Noor Developers - Booking Via Installment'
    })
  }
  const renderPageNumbers = () => {
    let pages = []
    const maxVisiblePages = 5

    if (totalPages <= maxVisiblePages) {
      // Show all pages if total pages is less than or equal to max visible pages
      pages = Array.from({ length: totalPages }, (_, i) => i + 1)
    } else {
      // Calculate range of visible pages
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
      let endPage = startPage + maxVisiblePages - 1

      // Adjust if end page exceeds total pages
      if (endPage > totalPages) {
        endPage = totalPages
        startPage = Math.max(1, endPage - maxVisiblePages + 1)
      }

      pages = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      )
    }

    return pages.map(pageNum => (
      <button
        key={pageNum}
        onClick={() => setCurrentPage(pageNum)}
        className={`flex items-center justify-center h-8 w-8 rounded-full text-sm font-medium transition-all duration-300 ${
          pageNum === currentPage
            ? `${theme.tailwind.bg13} text-white shadow-lg scale-110`
            : `bg-white/10 ${theme.tailwind.text9} hover:bg-slate-400 hover:text-white`
        }`}
      >
        {pageNum}
      </button>
    ))
  }

  // Add this after your table component
  const renderPagination = () => {
    if (totalPages <= 1) return null

    return (
      <div className='flex justify-center items-center gap-4 mt-6 mb-5 p-4'>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`flex items-center justify-center h-8 w-8 rounded-lg transition-all duration-300 ${
            currentPage === 1
              ? 'bg-slate-600 cursor-not-allowed'
              : `bg-slate-700 ${theme.tailwind.text9} hover:bg-slate-600`
          }`}
        >
          <ChevronLeft
            className={`w-4 h-4 ${
              currentPage === 1 ? 'text-slate-400' : `${theme.tailwind.text9}`
            }`}
          />
        </button>

        <div className='flex items-center gap-2'>
          {currentPage > 3 && totalPages > 5 && (
            <>
              <button
                onClick={() => setCurrentPage(1)}
                className={`flex items-center justify-center h-8 w-8 rounded-full text-sm font-medium bg-white/10 ${theme.tailwind.text9} hover:bg-slate-400 hover:text-white transition-all duration-300`}
              >
                1
              </button>
              <span className={`${theme.tailwind.text9}`}>...</span>
            </>
          )}

          {renderPageNumbers()}

          {currentPage < totalPages - 2 && totalPages > 5 && (
            <>
              <span className={`${theme.tailwind.text9}`}>...</span>
              <button
                onClick={() => setCurrentPage(totalPages)}
                className={`flex items-center justify-center h-8 w-8 rounded-full text-sm font-medium bg-white/10 ${theme.tailwind.text9} hover:bg-slate-400 hover:text-white transition-all duration-300`}
              >
                {totalPages}
              </button>
            </>
          )}
        </div>

        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`flex items-center justify-center h-8 w-8 rounded-lg transition-all duration-300 ${
            currentPage === totalPages
              ? 'bg-slate-600 cursor-not-allowed'
              : `bg-slate-700 ${theme.tailwind.text9} hover:bg-slate-600`
          }`}
        >
          <ChevronRight
            className={`w-4 h-4 ${
              currentPage === totalPages
                ? 'text-slate-400'
                : `${theme.tailwind.text9}`
            }`}
          />
        </button>
      </div>
    )
  }

  // Helper Functions
  const getCustomerName = customer => {
    return customer?._id.username || 'Unknown'
  }

  const formatCurrency = amount => {
    return new Intl.NumberFormat('en-PK', {
      style: 'currency',
      currency: 'PKR'
    }).format(amount)
  }

  const getSocietyDetails = societyId => {
    const society = societies.find(s => s._id === societyId)
    return society
      ? {
          name: society.name,
          location: society.location,
          image: society.society_image
        }
      : {
          name: 'Housing City',
          location: 'Main Branch, City Center',
          image: Logo
        }
  }

  const handleDelete = booking => {
    if (isEmployee) return
    setSelectedBooking(booking)
    setDeleteDialogOpen(true)
  }

  const confirmDelete = async () => {
    if (isEmployee) return
    if (selectedBooking) {
      await dispatch(deleteBooking(selectedBooking._id))
      setDeleteDialogOpen(false)
      setSelectedBooking(null)
    }
  }

  const handleEdit = booking => {
    if (isEmployee) return
    setSelectedBooking(booking)
    setEditFormData({
      booking_number: booking.booking_number,
      total_amount: booking.total_amount,
      receipt_no: booking.receipt_no,
      booking_date: booking.booking_date,
      customer_username: booking.customer_id?._id?.username || '',
      customer_id: booking.customer_id?._id || ''
    })
    setEditDialogOpen(true)
  }

  const handleUpdate = async () => {
    if (isEmployee) return
    if (selectedBooking && editFormData) {
      await dispatch(
        updateBooking({
          bookingId: selectedBooking._id,
          bookingData: editFormData
        })
      )
      setEditDialogOpen(false)
      setSelectedBooking(null)
    }
  }

  const formatDate = date => {
    const d = new Date(date)
    const day = String(d.getDate()).padStart(2, '0')
    const month = String(d.getMonth() + 1).padStart(2, '0')
    const year = d.getFullYear()
    return `${day}/${month}/${year}`
  }
  const handleExport = () => {
    let filteredBookings = bookings

    // Determine which bookings to export based on export type
    if (exportType === 'filtered') {
      // Apply date range filter if preset is selected
      if (exportPreset !== 'custom') {
        const dateRanges = getDateRanges()
        const selectedRange = dateRanges[exportPreset]
        filteredBookings = filterBookingsByDateRange(
          filteredBookings,
          selectedRange.start,
          selectedRange.end,
          activeSociety?._id
        )
      } else if (exportStartDate && exportEndDate) {
        // Custom date range
        filteredBookings = filterBookingsByDateRange(
          filteredBookings,
          exportStartDate,
          exportEndDate,
          activeSociety?._id
        )
      }
    } else if (exportType === 'all') {
      // Filter all bookings for the active society
      filteredBookings = bookings.filter(
        booking =>
          booking.payment_mode === 'Full' &&
          booking.plot_id?.society_id === activeSociety?._id
      )
    }

    // Format and export data
    // IMPORTANT: Pass the society name as the third argument
    const formattedData = formatExportData(
      filteredBookings,
      activeSociety?._id,
      activeSociety?.name || 'Unknown Society'
    )

    exportToExcel(
      formattedData,
      activeSociety?.name || 'Unknown Society',
      exportType === 'all'
        ? 'all_full_payment_bookings'
        : 'filtered_full_payment_bookings'
    )

    // Close export dialog
    setExportDialogOpen(false)
  }

  // Export Dialog Component
  const renderExportDialog = () =>
    exportDialogOpen && (
      <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50'>
        <div
          className={`${theme.tailwind.bg14} rounded-2xl p-6 max-w-md w-full`}
        >
          <div className='flex justify-between items-center mb-4'>
            <h2 className={`text-lg font-semibold ${theme.tailwind.text9}`}>
              Export Bookings
            </h2>
            <button
              onClick={() => setExportDialogOpen(false)}
              className={`${theme.tailwind.text}`}
            >
              <X className='w-5 h-5' />
            </button>
          </div>

          <div className='space-y-4'>
            {/* Export Type Selection */}
            <div>
              <label className={`text-sm font-medium ${theme.tailwind.text9}`}>
                Export Type
              </label>
              <select
                value={exportType}
                onChange={e => setExportType(e.target.value)}
                className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9}`}
              >
                <option value='filtered'>Filtered Data</option>
                <option value='all'>All Data</option>
              </select>
            </div>

            {/* Date Range Selection (only for filtered export) */}
            {exportType === 'filtered' && (
              <>
                <div>
                  <label
                    className={`text-sm font-medium ${theme.tailwind.text9}`}
                  >
                    Date Range Preset
                  </label>
                  <select
                    value={exportPreset}
                    onChange={e => setExportPreset(e.target.value)}
                    className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9}`}
                  >
                    <option value='custom'>Custom Date Range</option>
                    <option value='today'>Today</option>
                    <option value='thisMonth'>This Month</option>
                    <option value='lastMonth'>Last Month</option>
                    <option value='thisYear'>This Year</option>
                    <option value='lastYear'>Last Year</option>
                  </select>
                </div>

                {exportPreset === 'custom' && (
                  <div className='grid grid-cols-2 gap-4'>
                    <div>
                      <label
                        className={`text-sm font-medium ${theme.tailwind.text9}`}
                      >
                        Start Date
                      </label>
                      <ReactDatePicker
                        selected={exportStartDate}
                        onChange={date => setExportStartDate(date)}
                        selectsStart
                        startDate={exportStartDate}
                        endDate={exportEndDate}
                        className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9}`}
                      />
                    </div>
                    <div>
                      <label
                        className={`text-sm font-medium ${theme.tailwind.text9}`}
                      >
                        End Date
                      </label>
                      <ReactDatePicker
                        selected={exportEndDate}
                        onChange={date => setExportEndDate(date)}
                        selectsEnd
                        startDate={exportStartDate}
                        endDate={exportEndDate}
                        minDate={exportStartDate}
                        className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9}`}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className='flex justify-end space-x-4 mt-6'>
            <button
              onClick={() => setExportDialogOpen(false)}
              className={`px-4 py-2 border ${theme.tailwind.border11} rounded-lg ${theme.tailwind.text9} ${theme.tailwind.bgHover7}`}
            >
              Cancel
            </button>
            <button
              onClick={handleExport}
              className={`px-4 py-2 ${theme.tailwind.bg1} ${theme.tailwind.text} rounded-lg ${theme.tailwind.bgHover9}`}
              disabled={
                exportType === 'filtered' &&
                exportPreset === 'custom' &&
                (!exportStartDate || !exportEndDate)
              }
            >
              Export
            </button>
          </div>
        </div>
      </div>
    )

  return (
    <div
      className={`min-h-full ${gardient} rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-8 font-inter ${theme.tailwind.text9}`}
    >
      <div className='max-w-7xl mx-auto space-y-8'>
        {/* Vibrant Header Section */}
        <div className='flex flex-col md:flex-row justify-between items-center gap-4'>
          <div className='text-center md:text-left'>
            <h1
              className={`text-3xl sm:text-4xl md:text-5xl font-extrabold bg-clip-text text-transparent ${theme.tailwind.text8} mb-2`}
            >
              Full Payment Bookings
            </h1>
            <div
              className={`flex items-center justify-center md:justify-start space-x-2 ${theme.tailwind.text}`}
            >
              <MapPin className='w-5 h-5 sm:w-6 sm:h-6' />
              <span className='text-base sm:text-lg font-medium'>
                {activeSociety?.name || 'All Societies'}
              </span>
            </div>
          </div>

          <div className='flex flex-col sm:flex-row items-center sm:space-x-4 space-y-4 sm:space-y-0'>
            {/* Booking/Customer Search */}
            <div className='relative w-full sm:w-auto'>
              <input
                type='text'
                placeholder='Search booking no. or customer...'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className={`pl-10 pr-4 py-3 w-full sm:w-auto ${theme.tailwind.bg14} border ${theme.tailwind.border11} rounded-xl focus:ring-2 ${theme.tailwind.focusRing2} transition-all ${theme.tailwind.text9} ${theme.tailwind.placeHolder1}`}
              />
              <Search
                className={`absolute left-3 top-3 ${theme.tailwind.text10}`}
              />
            </div>

            {/* Plot Number Search */}
            <div className='relative w-full sm:w-auto'>
              <input
                type='text'
                placeholder='Search plot number...'
                value={plotSearchTerm}
                onChange={e => setPlotSearchTerm(e.target.value)}
                className={`pl-10 pr-4 py-3 w-full sm:w-auto ${theme.tailwind.bg14} border ${theme.tailwind.border11} rounded-xl focus:ring-2 ${theme.tailwind.focusRing2} transition-all ${theme.tailwind.text9} ${theme.tailwind.placeHolder1}`}
              />
              <MapPin
                className={`absolute left-3 top-3 ${theme.tailwind.text10}`}
              />
            </div>

            <div className='relative group w-full sm:w-auto'>
              <button
                className={`w-full sm:w-auto ${theme.tailwind.bg14} border ${theme.tailwind.border11} px-4 py-3 rounded-xl flex items-center justify-center sm:justify-start gap-2 ${theme.tailwind.bgHover7} transition-all ${theme.tailwind.text9}`}
              >
                <Filter className={`w-5 h-5 ${theme.tailwind.text10}`} />
                Sort
                <ChevronDown className='w-4 h-4' />
              </button>
              {/* Dropdown Menu */}
              <div
                className={`absolute right-0 mt-2 w-48 ${theme.tailwind.bg14} border ${theme.tailwind.border11} rounded-xl shadow-2xl hidden group-hover:block z-50 p-2`}
              >
                {[
                  { label: 'Latest', value: 'latest' },
                  { label: 'Amount: Low to High', value: 'amount-asc' },
                  { label: 'Amount: High to Low', value: 'amount-desc' }
                ].map(option => (
                  <button
                    key={option.value}
                    onClick={() => setSortOption(option.value)}
                    className={`w-full text-left px-3 py-2 rounded-lg ${
                      theme.tailwind.bgHover7
                    } transition-all ${
                      sortOption === option.value
                        ? `${theme.tailwind.bg1} ${theme.tailwind.text9}`
                        : `${theme.tailwind.text9}`
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>

            <div className='relative group w-full sm:w-auto'>
              <button
                onClick={() => setExportDialogOpen(true)}
                className={`flex items-center gap-2 ${theme.tailwind.bg14} border ${theme.tailwind.border11} px-4 py-3 rounded-xl ${theme.tailwind.bgHover7} transition-all ${theme.tailwind.text9}`}
              >
                <Download className={`w-5 h-5 ${theme.tailwind.text10}`} />
                Export Data
              </button>
            </div>
          </div>
        </div>
        {/* Booking Table */}
        <div
          className={`${theme.tailwind.bg6} border ${theme.tailwind.border11} rounded-2xl overflow-hidden shadow-2xl backdrop-blur-xl`}
        >
          {paginatedBookings.length > 0 ? (
            <div className='overflow-x-auto'>
              <table className='w-full'>
                <thead
                  className={`${theme.tailwind.bg15} backdrop-blur-sm border-b ${theme.tailwind.border11}`}
                >
                  <tr>
                    {['Booking', 'Customer', 'Amount', 'Actions'].map(
                      header => (
                        <th
                          key={header}
                          className={`px-6 py-4 text-left text-xs font-semibold ${theme.tailwind.text9} uppercase tracking-wider`}
                        >
                          {header}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {paginatedBookings.map(booking => (
                    <tr
                      key={booking._id}
                      className={`border-b ${theme.tailwind.border12} ${theme.tailwind.bgHover8} transition-all duration-300`}
                    >
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>
                          <div
                            className={`${theme.tailwind.bg1} p-3 rounded-xl mr-4`}
                          >
                            <FileText
                              className={`w-4 h-4 ${theme.tailwind.text}`}
                            />
                          </div>
                          <div className='relative'>
                            <p
                              className={`${theme.tailwind.text9} font-semibold `}
                            >
                              Booking no.{booking.booking_number}
                            </p>

                            <p className={`text-xs ${theme.tailwind.text10} `}>
                              {formatDate(booking.booking_date)}
                            </p>
                            <p className={`text-xs ${theme.tailwind.text10} `}>
                              Receipt no.{booking.receipt_no}
                            </p>

                            {booking.offertag && (
                              <div className='relative -bottom-1 -right-0'>
                                <div className='bg-red-600 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-lg flex items-center space-x-1'>
                                  <span>{booking.offertag}</span>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='h-3 w-3'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      d='M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z'
                                      clipRule='evenodd'
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>

                      <td className='px-6 py-4'>
                        <div>
                          <p
                            className={`${theme.tailwind.text9} text-lg font-semibold`}
                          >
                            {getCustomerName(booking.customer_id)}
                          </p>
                          <p className={`text-xs ${theme.tailwind.text10}`}>
                            Installment Payment
                          </p>
                          <p
                            className={`${theme.tailwind.text9} font-bold mr-2`}
                          >
                            Plot no. {booking.plot_id?.plot_number}
                          </p>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>
                          <p
                            className={`${theme.tailwind.text9} font-bold mr-2`}
                          >
                            {formatCurrency(booking.total_amount)}
                          </p>
                          <span className='bg-green-500/20 text-green-400 px-2 py-1 rounded-full text-xs'>
                            Paid
                          </span>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex items-center space-x-2'>
                          <button
                            onClick={() => {
                              handlePrint(booking)
                            }}
                            className={`${theme.tailwind.bg1} ${theme.tailwind.text} p-2 rounded-xl ${theme.tailwind.bgHover9} transition-all`}
                          >
                            <Printer className='w-5 h-5' />
                          </button>
                          {!isEmployee && (
                            <>
                              <button
                                onClick={() => handleEdit(booking)}
                                className='bg-blue-500/20 text-blue-400 p-2 rounded-xl hover:bg-blue-500/40 transition-all'
                              >
                                <Edit className='w-5 h-5' />
                              </button>
                              <button
                                onClick={() => handleDelete(booking)}
                                className='bg-red-500/20 text-red-400 p-2 rounded-xl hover:bg-red-500/40 transition-all'
                              >
                                <Trash2 className='w-5 h-5' />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='flex flex-col items-center justify-center py-20 space-y-6'>
              <div
                className={`${theme.tailwind.bg14} p-6 rounded-full animate-pulse`}
              >
                <MapPin className={`w-12 h-12 ${theme.tailwind.text10}`} />
              </div>
              <p
                className={`text-3xl font-bold bg-clip-text text-transparent ${theme.tailwind.text8}`}
              >
                No Bookings Found
              </p>
              <p className={`${theme.tailwind.text10} text-center max-w-md`}>
                There are no full payment bookings for the selected society. Try
                adjusting your filters or selecting a different society.
              </p>
            </div>
          )}
        </div>
      </div>
      {renderPagination()}

      {/* Delete Dialog */}
      {deleteDialogOpen && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50'>
          <div
            className={`${theme.tailwind.bg14} rounded-2xl p-6 max-w-md w-full`}
          >
            <div className='flex justify-between items-center mb-4'>
              <h2 className={`text-lg font-semibold ${theme.tailwind.text9}`}>
                Confirm Deletion
              </h2>
              <button
                onClick={() => setDeleteDialogOpen(false)}
                className={`${theme.tailwind.text}`}
              >
                <X className='w-5 h-5' />
              </button>
            </div>
            <p className={`${theme.tailwind.text9} mb-6`}>
              Are you sure you want to delete booking{' '}
              {selectedBooking?.booking_number}?
            </p>
            <div className='flex justify-end space-x-4'>
              <button
                onClick={() => setDeleteDialogOpen(false)}
                className={`px-4 py-2 border ${theme.tailwind.border11} rounded-lg ${theme.tailwind.text9} ${theme.tailwind.bgHover7}`}
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className='px-4 py-2 bg-red-500/20 text-red-400 rounded-lg hover:bg-red-500/40'
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Dialog */}
      {editDialogOpen && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50'>
          <div
            className={`${theme.tailwind.bg14} rounded-2xl p-6 max-w-md w-full`}
          >
            <div className='flex justify-between items-center mb-4'>
              <h2 className={`text-lg font-semibold ${theme.tailwind.text9}`}>
                Edit Booking
              </h2>
              <button
                onClick={() => setEditDialogOpen(false)}
                className={`${theme.tailwind.text}`}
              >
                <X className='w-5 h-5' />
              </button>
            </div>
            <div className='space-y-4'>
              <div>
                <label
                  className={`text-sm font-medium ${theme.tailwind.text9}`}
                >
                  Customer Username
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    value={editFormData.customer_username || ''}
                    onChange={e =>
                      setEditFormData({
                        ...editFormData,
                        customer_username: e.target.value
                      })
                    }
                    readOnly
                    className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9} pr-8`}
                  />
                  <Lock
                    className={`absolute top-1/2 right-3 transform -translate-y-1/2 ${theme.tailwind.text9}`}
                  />
                </div>
              </div>

              <div>
                <label
                  className={`text-sm font-medium ${theme.tailwind.text9}`}
                >
                  Booking Number
                </label>
                <input
                  type='text'
                  value={editFormData.booking_number || ''}
                  onChange={e =>
                    setEditFormData({
                      ...editFormData,
                      booking_number: e.target.value
                    })
                  }
                  className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9}`}
                />
              </div>
              <div>
                <label
                  className={`text-sm font-medium ${theme.tailwind.text9}`}
                >
                  Total Amount
                </label>
                <input
                  type='text'
                  value={
                    editFormData.total_amount
                      ? parseInt(editFormData.total_amount, 10).toLocaleString(
                          'en-US'
                        )
                      : ''
                  }
                  onChange={e => {
                    const rawValue = e.target.value.replace(/,/g, '') // Remove commas
                    if (!isNaN(rawValue)) {
                      setEditFormData({
                        ...editFormData,
                        total_amount: rawValue // Store raw numeric value
                      })
                    }
                  }}
                  className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9}`}
                />
              </div>

              <div>
                <label
                  className={`text-sm font-medium ${theme.tailwind.text9}`}
                >
                  Receipt Number
                </label>
                <input
                  type='text'
                  value={editFormData.receipt_no || ''}
                  onChange={e =>
                    setEditFormData({
                      ...editFormData,
                      receipt_no: e.target.value
                    })
                  }
                  className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9}`}
                />
              </div>

              <div>
                <label
                  className={`text-sm font-medium ${theme.tailwind.text9} block mb-2`}
                >
                  Booking Date
                </label>
                <ReactDatePicker
                  selected={
                    editFormData.booking_date
                      ? new Date(editFormData.booking_date)
                      : null
                  }
                  onChange={date =>
                    setEditFormData({
                      ...editFormData,
                      booking_date: date ? date.toISOString().split('T')[0] : ''
                    })
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  wrapperClassName='w-full'
                  dateFormat='dd/MM/yyyy'
                  className={`w-full p-2 ${theme.tailwind.bg16} border ${theme.tailwind.border11} rounded-lg mt-1 ${theme.tailwind.text9}`}
                />
              </div>
            </div>
            <div className='flex justify-end space-x-4 mt-6'>
              <button
                onClick={() => setEditDialogOpen(false)}
                className={`px-4 py-2 border ${theme.tailwind.border11} rounded-lg ${theme.tailwind.text9} ${theme.tailwind.bgHover7}`}
              >
                Cancel
              </button>
              <button
                onClick={handleUpdate}
                className={`px-4 py-2 ${theme.tailwind.bg1} ${theme.tailwind.text} rounded-lg ${theme.tailwind.bgHover9}`}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {renderExportDialog()}
    </div>
  )
}

export default FullPaymentBookings
