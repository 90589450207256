// import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { Formik, Form, Field, ErrorMessage } from 'formik'
// import * as Yup from 'yup'
// import { useSnackbar } from 'notistack'
// import { addTransaction } from '../store/slices/transactionSlice'
// import { useNavigate } from 'react-router-dom'
// import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'
// import { format } from 'date-fns'
// import 'react-datepicker/dist/react-datepicker.css'
// import TransactionDatePicker from './component/TransactionDatePicker'
// import Modal from 'react-modal'
// import { ChevronDown, ChevronUp, MapPinHouse } from 'lucide-react'
// import TransactionModal from './component/TransactionModal'
// Modal.setAppElement('#root')

// const modalOverlayStyle = {
//   position: 'fixed',
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: 'rgba(0, 0, 0, 0.7)',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   zIndex: 1000,
//   backdropFilter: 'blur(4px)',
//   padding: '1rem'
// }

// const modalStyle = {
//   position: 'relative',
//   background: '#1E293B',
//   borderRadius: '8px',
//   padding: '20px',
//   width: '100%',
//   maxWidth: '800px',
//   maxHeight: '90vh',
//   overflow: 'auto',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
//   zIndex: 1001
// }

// const TransactionCreate = () => {
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const { enqueueSnackbar } = useSnackbar()
//   const { error } = useSelector(state => state.transactions)
//   const { societies } = useSelector(state => state.societies)
//   const { activeUser } = useSelector(state => state.auth)
//   const [customTransactionTypes, setCustomTransactionTypes] = useState([])
//   const { activeSociety } = useSidebarContext()
//   const [formKey, setFormKey] = useState(0)
//   const [isModalOpen, setIsModalOpen] = useState(false)
//   const [selectedSocieties, setSelectedSocieties] = useState([])
//   const [isOpen, setIsOpen] = useState(false)

//   useEffect(() => {
//     setFormKey(prev => prev + 1)
//   }, [activeSociety?._id])
//   // Validation Schema with Pakistani-specific validations
//   // const TransactionValidationSchema = Yup.object().shape({
//   //   // societies: Yup.string().required("سوسائٹی کا انتخاب کریں"),
//   //   amount: Yup.number()
//   //     .required("رقم درج کریں")
//   //     .positive("رقم مثبت ہونی چاہیے")
//   //     .max(100000000, "زیادہ سے زیادہ رقم 100,000,000 ہو سکتی ہے"),
//   //   transaction_type: Yup.string().required("ٹرانزیکشن کی قسم منتخب کریں"),
//   //   transaction_direction: Yup.string().required("ٹرانزیکشن کی سمت منتخب کریں"),
//   //   transaction_type_custom: Yup.string().when("transaction_type", {
//   //     is: "other",
//   //     then: (schema) => schema.required("کسٹم ٹرانزیکشن کی قسم درج کریں"),
//   //     otherwise: (schema) => schema.notRequired(),
//   //   }),
//   //   description: Yup.string().max(500, "تفصیل 500 حروف سے زیادہ نہیں ہو سکتی"),
//   //   payment_method: Yup.string().required("ادائیگی کا طریقہ درج کریں"),
//   //   // receipt_no: Yup.string().required('ادائیگی کا طریقہ درج کریں')
//   // });
//   const TransactionValidationSchema = Yup.object().shape({
//     amount: Yup.object()
//       .required('رقم درج کریں') // Ensure the object itself is present
//       .test(
//         'amount-keys-test',
//         'رقم کے تمام خانے پُر کریں',
//         value =>
//           value &&
//           Object.values(value).length > 0 &&
//           Object.values(value).every(
//             amount =>
//               amount !== undefined &&
//               amount !== null &&
//               !isNaN(parseFloat(amount)) &&
//               parseFloat(amount) > 0 &&
//               parseFloat(amount) <= 100000000
//           )
//       )
//       .test(
//         'amount-max-test',
//         'زیادہ سے زیادہ رقم 100,000,000 ہو سکتی ہے',
//         value =>
//           value &&
//           Object.values(value).every(amount => parseFloat(amount) <= 100000000)
//       ),
//     transaction_type: Yup.string().required('ٹرانزیکشن کی قسم منتخب کریں'),
//     transaction_direction: Yup.string().required('ٹرانزیکشن کی سمت منتخب کریں'),
//     transaction_type_custom: Yup.string().when('transaction_type', {
//       is: 'other',
//       then: schema => schema.required('کسٹم ٹرانزیکشن کی قسم درج کریں'),
//       otherwise: schema => schema.notRequired()
//     }),
//     description: Yup.string().max(500, 'تفصیل 500 حروف سے زیادہ نہیں ہو سکتی'),
//     payment_method: Yup.string().required('ادائیگی کا طریقہ درج کریں')
//   })

//   // Input and Label Classes
//   const inputClasses =
//     'mt-1 block w-full px-4 py-3 bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl focus:ring-2 focus:ring-teal-500/50 focus:border-teal-400/30 text-gray-100 placeholder-gray-400 transition-all duration-300 hover:border-teal-400/20 shadow-lg shadow-gray-900/20'

//   const labelClasses =
//     'block text-sm font-medium text-teal-400 mb-2 tracking-wide'

//   const errorClasses = 'text-red-500 text-xs mt-1'

//   if (!activeSociety) {
//     return (
//       <div className='min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 flex items-center justify-center'>
//         <div className='text-center text-gray-400'>
//           <h2 className='text-2xl font-semibold mb-2'>No Society Selected</h2>
//           <p>Please select a society to create transactions</p>
//         </div>
//       </div>
//     )
//   }

//   const openModal = () => setIsModalOpen(true)
//   const closeModal = () => {
//     setIsModalOpen(false)
//     setSelectedSocieties([])
//   }

//   const handleModalSubmit = async values => {
//     try {
//       // Ensure every society has an amount
//       const formattedAmounts = {}
//       values.societies.forEach(societyId => {
//         const amount = values.amounts[societyId]
//         if (!amount || isNaN(amount)) {
//           throw new Error(
//             `Amount is missing or invalid for society ID: ${societyId}`
//           )
//         }
//         formattedAmounts[societyId] = Number(amount)
//       })

//       const payload = {
//         societies: values.societies,
//         amount: formattedAmounts,
//         transaction_type: values.transaction_type,
//         transaction_direction: values.transaction_direction,
//         payment_method: values.payment_method,
//         transaction_date: values.transaction_date
//           ? format(new Date(values.transaction_date), 'yyyy-MM-dd') // Correct format
//           : null,
//         description: values.description || '',
//         user_id: activeUser.id
//       }

//       const resultAction = await dispatch(addTransaction(payload))

//       if (addTransaction.fulfilled.match(resultAction)) {
//         enqueueSnackbar('Transaction successfully created!', {
//           variant: 'success'
//         })
//         closeModal()
//         navigate('/create/transaction')
//       } else {
//         throw new Error(resultAction.error.message)
//       }
//     } catch (error) {
//       enqueueSnackbar(error.message || 'Failed to create transaction', {
//         variant: 'error'
//       })
//     }
//   }

//   return (
//     <div className='min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] py-12 px-4 sm:px-6 lg:px-8'>
//       <div className='max-w-4xl mx-auto'>
//         <div className='bg-gray-800/30 backdrop-blur-xl rounded-3xl shadow-2xl p-8 border border-gray-700/30'>
//           <div className='relative'>
//             <div className='absolute inset-0 overflow-hidden'>
//               <div className='absolute -left-4 -top-4 w-72 h-72 bg-teal-500/10 rounded-full blur-3xl'></div>
//               <div className='absolute -right-4 -top-4 w-72 h-72 bg-blue-500/10 rounded-full blur-3xl'></div>
//               <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-32 bg-gradient-to-r from-teal-500/5 via-blue-500/5 to-purple-500/5 blur-3xl transform rotate-12'></div>
//             </div>

//             <div className='relative'>
//               <div className='text-center space-y-6 py-3'>
//                 <div className='inline-flex items-center px-4 py-2 rounded-full bg-gradient-to-r from-teal-500/10 to-blue-500/10 border border-teal-500/20 backdrop-blur-sm mb-4 transition-all duration-300 hover:from-teal-500/20 hover:to-blue-500/20'>
//                   <span className='animate-pulse w-2 h-2 rounded-full bg-teal-400 mr-2'></span>
//                   <span className='text-teal-400 text-sm font-medium'>
//                     Transaction Management
//                   </span>
//                 </div>
//                 <div className='space-y-4'>
//                   <h1 className='text-4xl font-extrabold'>
//                     <span className='text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300'>
//                       Create Society Transaction
//                     </span>
//                   </h1>
//                   <div className='transform transition-all duration-300 hover:scale-105'>
//                     <div className='relative group'>
//                       <div className='absolute -inset-1 rounded-lg bg-gradient-to-r from-teal-500 via-blue-500 to-purple-500 opacity-30 blur group-hover:opacity-40 transition duration-300'></div>
//                       <div className='relative px-6 py-3 bg-gray-900 ring-1 ring-gray-800/50 rounded-lg'>
//                         <h2 className='text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-blue-400 to-purple-400'>
//                           {activeSociety.name}
//                         </h2>
//                       </div>
//                     </div>
//                   </div>

//                   <div className='flex items-center justify-center space-x-2 text-gray-400 group'>
//                     <svg
//                       className='w-4 h-4 transform transition-transform duration-300 group-hover:scale-110'
//                       fill='none'
//                       stroke='currentColor'
//                       viewBox='0 0 24 24'
//                     >
//                       <path
//                         strokeLinecap='round'
//                         strokeLinejoin='round'
//                         strokeWidth='2'
//                         d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
//                       />
//                       <path
//                         strokeLinecap='round'
//                         strokeLinejoin='round'
//                         strokeWidth='2'
//                         d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
//                       />
//                     </svg>
//                     <span className='text-lg transition-colors duration-300 group-hover:text-teal-400'>
//                       {activeSociety.location}
//                     </span>
//                   </div>
//                 </div>
//                 <div className='relative py-4'>
//                   <div className='absolute inset-0 flex items-center'>
//                     <div className='w-full border-t border-gray-800'></div>
//                   </div>

//                   <div className='relative px-6 py-3 bg-gray-900 ring-1 ring-gray-800/50 rounded-lg'>
//                     <h3 className='text-transparent text-lg bg-clip-text bg-gradient-to-r from-teal-500 to-teal-300'>
//                       Create Socities Transaction
//                     </h3>

//                     <div className='relative flex justify-center'>
//                       <div className='px-4 bg-gray-900'>
//                         <div
//                           onClick={openModal}
//                           className=' cursor-pointer w-8 h-8 rounded-full bg-gradient-to-r from-teal-500 to-blue-500 flex items-center justify-center transform transition-transform duration-300 hover:scale-110 hover:rotate-180'
//                         >
//                           <svg
//                             className='w-4 h-4 text-white'
//                             fill='none'
//                             stroke='currentColor'
//                             viewBox='0 0 24 24'
//                           >
//                             <path
//                               strokeLinecap='round'
//                               strokeLinejoin='round'
//                               strokeWidth='2'
//                               d='M12 6v6m0 0v6m0-6h6m-6 0H6'
//                             />
//                           </svg>
//                         </div>
//                         <TransactionModal
//                           isOpen={isModalOpen}
//                           closeModal={closeModal}
//                           societies={societies}
//                           onSubmit={handleModalSubmit}
//                           modalStyle={modalStyle}
//                           modalOverlayStyle={modalOverlayStyle}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {error && (
//               <div className='mt-6 p-4 bg-red-900/20 backdrop-blur-sm border border-red-500/30 rounded-xl relative overflow-hidden group transition-all duration-300 hover:bg-red-900/30'>
//                 <div className='relative flex items-center space-x-3'>
//                   <svg
//                     className='w-5 h-5 text-red-400 animate-pulse'
//                     fill='none'
//                     stroke='currentColor'
//                     viewBox='0 0 24 24'
//                   >
//                     <path
//                       strokeLinecap='round'
//                       strokeLinejoin='round'
//                       strokeWidth='2'
//                       d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
//                     />
//                   </svg>
//                   <span className='text-red-400 font-medium'>{error}</span>
//                 </div>
//               </div>
//             )}
//           </div>

//           <Formik
//             key={formKey}
//             initialValues={{
//               // societies: "",
//               amount: { [activeSociety._id]: '' },
//               transaction_type: '',
//               transaction_type_custom: '',
//               transaction_direction: '',
//               payment_method: '',
//               description: '',
//               transaction_date: null
//               // receipt_no: ''
//             }}
//             validationSchema={TransactionValidationSchema}
//             // onSubmit={async (values, { resetForm, setSubmitting }) => {
//             onSubmit={async (values, { setFieldValue, setSubmitting }) => {
//               try {
//                 const formattedDate = values.transaction_date
//                   ? format(new Date(values.transaction_date), 'yyyy-MM-dd')
//                   : null

//                 const finalTransactionType =
//                   values.transaction_type === 'other'
//                     ? values.transaction_type_custom
//                     : values.transaction_type

//                 const updatedFormData = {
//                   ...values,
//                   user_id: activeUser.id,
//                   transaction_type: finalTransactionType,
//                   societies: [activeSociety._id],
//                   transaction_date: formattedDate,
//                   amount: {
//                     [activeSociety._id]: parseFloat(
//                       values.amount[activeSociety._id]
//                     ) // Ensure numeric value
//                   }
//                 }

//                 const resultAction = await dispatch(
//                   addTransaction(updatedFormData)
//                 )

//                 if (addTransaction.fulfilled.match(resultAction)) {
//                   enqueueSnackbar('Transaction successfully added!', {
//                     variant: 'success'
//                   })
//                   setFieldValue('amount', { [activeSociety._id]: '' })

//                   setFieldValue('description', '')

//                   navigate('/create/transaction')
//                 } else {
//                   enqueueSnackbar('Failed to add transaction', {
//                     variant: 'error'
//                   })
//                 }
//               } catch (err) {
//                 console.error('Error creating transaction:', err)
//                 enqueueSnackbar('An error occurred', { variant: 'error' })
//               } finally {
//                 setSubmitting(false)
//               }
//             }}
//           >
//             {({ values, setFieldValue, isSubmitting, errors, touched }) => (
//               <Form className='space-y-8'>
//                 <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
//                   <div className='group'>
//                     <label htmlFor='amount' className={labelClasses}>
//                       Amount
//                     </label>
//                     <Field name={`amount.${activeSociety._id}`}>
//                       {({ field, form }) => (
//                         <input
//                           type='text'
//                           id={`amount-${activeSociety._id}`}
//                           {...field}
//                           className={inputClasses}
//                           placeholder='Enter amount'
//                           value={
//                             field.value
//                               ? Number(
//                                   field.value.toString().replace(/,/g, '')
//                                 ).toLocaleString()
//                               : ''
//                           }
//                           onChange={event => {
//                             const rawValue = event.target.value.replace(
//                               /,/g,
//                               ''
//                             )
//                             if (!isNaN(rawValue) || rawValue === '') {
//                               const updatedAmount = {
//                                 ...form.values.amount,
//                                 [activeSociety._id]: rawValue
//                               }
//                               form.setFieldValue('amount', updatedAmount)
//                             }
//                           }}
//                         />
//                       )}
//                     </Field>

//                     <ErrorMessage
//                       name='amount'
//                       component='div'
//                       className={errorClasses}
//                     />
//                   </div>

//                   {/* Transaction Type */}
//                   <div>
//                     <label htmlFor='transaction_type' className={labelClasses}>
//                       Transaction Type
//                     </label>
//                     <Field
//                       as='select'
//                       id='transaction_type'
//                       name='transaction_type'
//                       className={inputClasses}
//                       onChange={e => {
//                         const value = e.target.value
//                         setFieldValue('transaction_type', value)
//                         if (
//                           value !== 'other' &&
//                           value !== '' &&
//                           !customTransactionTypes.includes(value)
//                         ) {
//                           setCustomTransactionTypes([
//                             ...customTransactionTypes,
//                             value
//                           ])
//                         }
//                       }}
//                     >
//                       <option value=''>Select Type</option>
//                       <option
//                         value='Full Payment'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Full Payment
//                       </option>
//                       <option
//                         value='Partial Payment'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Partial Payment
//                       </option>
//                       <option
//                         value='Installment Payment'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Installment Payment
//                       </option>
//                       <option
//                         value='Salary Payment'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Salary Payment
//                       </option>
//                       <option
//                         value='Expense Payment'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Expense Payment
//                       </option>
//                       <option
//                         value='Resell Payment'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Resell Payment
//                       </option>
//                       <option
//                         value='other'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Other
//                       </option>
//                       {customTransactionTypes.map((type, index) => (
//                         <option key={index} value={type}>
//                           {type}
//                         </option>
//                       ))}
//                     </Field>
//                     <ErrorMessage
//                       name='transaction_type'
//                       component='div'
//                       className={errorClasses}
//                     />
//                     {values.transaction_type === 'other' && (
//                       <>
//                         <Field
//                           type='text'
//                           id='transaction_type_custom'
//                           name='transaction_type_custom'
//                           className={`${inputClasses} mt-2`}
//                           placeholder='Enter custom transaction type'
//                           onChange={e => {
//                             setFieldValue(
//                               'transaction_type_custom',
//                               e.target.value
//                             )
//                             if (
//                               !customTransactionTypes.includes(e.target.value)
//                             ) {
//                               setCustomTransactionTypes([
//                                 ...customTransactionTypes,
//                                 e.target.value
//                               ])
//                             }
//                           }}
//                         />
//                         <ErrorMessage
//                           name='transaction_type_custom'
//                           component='div'
//                           className={errorClasses}
//                         />
//                       </>
//                     )}
//                   </div>

//                   {/* Transaction Direction */}
//                   <div className='group'>
//                     <label
//                       htmlFor='transaction_direction'
//                       className={labelClasses}
//                     >
//                       Transaction Direction
//                     </label>
//                     <Field
//                       as='select'
//                       id='transaction_direction'
//                       name='transaction_direction'
//                       className={`${inputClasses} cursor-pointer`}
//                     >
//                       <option value=''>Select Direction</option>
//                       <option
//                         value='Income'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Income
//                       </option>
//                       <option
//                         value='Expense'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Expense
//                       </option>
//                     </Field>
//                     <ErrorMessage
//                       name='transaction_direction'
//                       component='div'
//                       className={errorClasses}
//                     />
//                   </div>

//                   {/* Payment Method */}
//                   <div className='group'>
//                     <label htmlFor='payment_method' className={labelClasses}>
//                       Payment Method
//                     </label>
//                     <Field
//                       as='select'
//                       id='payment_method'
//                       name='payment_method'
//                       className={`${inputClasses} cursor-pointer`}
//                     >
//                       <option value=''>Select Payment Method</option>
//                       <option
//                         value='Bank Transfer'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Bank Transfer
//                       </option>
//                       <option
//                         value='Cash Transfer'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Cash Transfer
//                       </option>
//                       <option
//                         value='JazzCash'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         JazzCash
//                       </option>
//                       <option
//                         value='Easypaisa'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Easypaisa
//                       </option>
//                       <option
//                         value='UPaisa'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         UPaisa
//                       </option>
//                       <option
//                         value='SadaPay'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         SadaPay
//                       </option>
//                       <option
//                         value='NayaPay'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         NayaPay
//                       </option>
//                       <option
//                         value='PayPak'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         PayPak
//                       </option>
//                       <option
//                         value='Other'
//                         className='bg-slate-800 text-teal-300'
//                       >
//                         Other
//                       </option>
//                     </Field>
//                     <ErrorMessage
//                       name='payment_method'
//                       component='div'
//                       className={errorClasses}
//                     />
//                   </div>

//                   <div className='group'>
//                     <label htmlFor='transaction_date' className={labelClasses}>
//                       Date
//                     </label>
//                     <Field name='transaction_date'>
//                       {({ field, form }) => (
//                         <TransactionDatePicker field={field} form={form} />
//                       )}
//                     </Field>
//                     <ErrorMessage
//                       name='transaction_date'
//                       component='div'
//                       className={errorClasses}
//                     />
//                   </div>

//                   {/* Description */}
//                   <div className='md:col-span-2 group'>
//                     <label htmlFor='description' className={labelClasses}>
//                       Description (Optional)
//                     </label>
//                     <Field
//                       as='textarea'
//                       id='description'
//                       name='description'
//                       rows={4}
//                       className={inputClasses}
//                       placeholder='Enter additional details about the transaction'
//                     />
//                     <ErrorMessage
//                       name='description'
//                       component='div'
//                       className={errorClasses}
//                     />
//                   </div>
//                 </div>
//                 {/* Submit Button */}
//                 <div className='pt-6'>
//                   <button
//                     type='submit'
//                     disabled={isSubmitting}
//                     className='w-full bg-gradient-to-r from-teal-500 to-teal-400 text-white py-4 px-6 rounded-xl font-medium
//                              hover:from-teal-400 hover:to-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500
//                              focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-300
//                              disabled:opacity-50 disabled:cursor-not-allowed shadow-lg shadow-teal-500/20
//                              hover:shadow-xl hover:shadow-teal-500/30 transform hover:-translate-y-0.5'
//                   >
//                     {isSubmitting ? (
//                       <span className='flex items-center justify-center'>
//                         <svg
//                           className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
//                           xmlns='http://www.w3.org/2000/svg'
//                           fill='none'
//                           viewBox='0 0 24 24'
//                         >
//                           <circle
//                             className='opacity-25'
//                             cx='12'
//                             cy='12'
//                             r='10'
//                             stroke='currentColor'
//                             strokeWidth='4'
//                           ></circle>
//                           <path
//                             className='opacity-75'
//                             fill='currentColor'
//                             d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
//                           ></path>
//                         </svg>
//                         Creating...
//                       </span>
//                     ) : (
//                       'Create Transaction'
//                     )}
//                   </button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default TransactionCreate

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { addTransaction } from "../store/slices/transactionSlice";
import { useNavigate } from "react-router-dom";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import TransactionDatePicker from "./component/TransactionDatePicker";
import Modal from "react-modal";
import {
  ChevronDown,
  ChevronUp,
  AlertCircle,
  DollarSign,
  Calendar,
  CreditCard,
  FileText,
  Zap,
  Gift,
} from "lucide-react";
import TransactionModal from "./component/TransactionModal";
Modal.setAppElement("#root");

const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
  backdropFilter: "blur(8px)",
  padding: "1rem",
};

const modalStyle = {
  position: "relative",
  background: "linear-gradient(135deg, #1E293B 0%, #0F172A 100%)",
  borderRadius: "16px",
  padding: "24px",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "90vh",
  overflow: "auto",
  boxShadow:
    "0 20px 40px rgba(0, 0, 0, 0.4), 0 0 100px rgba(78, 222, 198, 0.1)",
  zIndex: 1001,
  border: "1px solid rgba(78, 222, 198, 0.1)",
};

const TransactionCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { error } = useSelector((state) => state.transactions);
  const { societies } = useSelector((state) => state.societies);
  const { activeUser } = useSelector((state) => state.auth);
  const [customTransactionTypes, setCustomTransactionTypes] = useState([]);
  const { activeSociety } = useSidebarContext();
  const [formKey, setFormKey] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSocieties, setSelectedSocieties] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [animateForm, setAnimateForm] = useState(false);

  useEffect(() => {
    setFormKey((prev) => prev + 1);
  }, [activeSociety?._id]);

  useEffect(() => {
    // Trigger animation after component mounts
    setAnimateForm(true);
  }, []);

  const TransactionValidationSchema = Yup.object().shape({
    amount: Yup.object()
      .required("رقم درج کریں")
      .test(
        "amount-keys-test",
        "رقم کے تمام خانے پُر کریں",
        (value) =>
          value &&
          Object.values(value).length > 0 &&
          Object.values(value).every(
            (amount) =>
              amount !== undefined &&
              amount !== null &&
              !isNaN(parseFloat(amount)) &&
              parseFloat(amount) > 0 &&
              parseFloat(amount) <= 100000000
          )
      )
      .test(
        "amount-max-test",
        "زیادہ سے زیادہ رقم 100,000,000 ہو سکتی ہے",
        (value) =>
          value &&
          Object.values(value).every(
            (amount) => parseFloat(amount) <= 100000000
          )
      ),
    transaction_type: Yup.string().required("ٹرانزیکشن کی قسم منتخب کریں"),
    transaction_direction: Yup.string().required("ٹرانزیکشن کی سمت منتخب کریں"),
    transaction_type_custom: Yup.string().when("transaction_type", {
      is: "other",
      then: (schema) => schema.required("کسٹم ٹرانزیکشن کی قسم درج کریں"),
      otherwise: (schema) => schema.notRequired(),
    }),
    description: Yup.string().max(500, "تفصیل 500 حروف سے زیادہ نہیں ہو سکتی"),
    payment_method: Yup.string().required("ادائیگی کا طریقہ درج کریں"),
  });

  // Enhanced Input and Label Classes
  const inputClasses =
    "mt-1 block w-full px-4 py-3 bg-slate-800/60 backdrop-blur-sm border border-slate-700/50 rounded-xl focus:ring-2 focus:ring-emerald-500/60 focus:border-emerald-400/40 text-slate-100 placeholder-slate-400 transition-all duration-300 hover:border-emerald-400/30 shadow-lg shadow-slate-900/30 outline-none";

  const labelClasses =
    "block text-sm font-medium text-emerald-400 mb-2 tracking-wide flex items-center space-x-2";

  const errorClasses = "text-rose-500 text-xs mt-1 font-medium";

  // Form section styles
  const formSectionClass =
    "bg-slate-800/20 backdrop-blur-md rounded-2xl p-6 border border-slate-700/30 shadow-xl transition-all duration-500 hover:shadow-emerald-900/10 relative overflow-hidden";

  if (!activeSociety) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 flex items-center justify-center">
        <div className="text-center bg-slate-800/30 backdrop-blur-lg p-10 rounded-2xl border border-slate-700/30 shadow-2xl transform transition-all duration-500 hover:scale-105">
          <div className="mb-6 flex justify-center">
            <div className="w-16 h-16 rounded-full bg-rose-500/20 flex items-center justify-center border border-rose-500/30 shadow-lg shadow-rose-500/10">
              <AlertCircle className="w-8 h-8 text-rose-400" />
            </div>
          </div>
          <h2 className="text-2xl font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-rose-400 to-amber-400">
            No Society Selected
          </h2>
          <p className="text-slate-400">
            Please select a society to create transactions
          </p>
          <div className="mt-8">
            <button
              onClick={() => navigate("/")}
              className="px-6 py-2 bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-emerald-500/30"
            >
              Go to Dashboard
            </button>
          </div>
        </div>
      </div>
    );
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSocieties([]);
  };

  const handleModalSubmit = async (values) => {
    try {
      // Ensure every society has an amount
      const formattedAmounts = {};
      values.societies.forEach((societyId) => {
        const amount = values.amounts[societyId];
        if (!amount || isNaN(amount)) {
          throw new Error(
            `Amount is missing or invalid for society ID: ${societyId}`
          );
        }
        formattedAmounts[societyId] = Number(amount);
      });

      const payload = {
        societies: values.societies,
        amount: formattedAmounts,
        transaction_type: values.transaction_type,
        transaction_direction: values.transaction_direction,
        payment_method: values.payment_method,
        transaction_date: values.transaction_date
          ? format(new Date(values.transaction_date), "yyyy-MM-dd")
          : null,
        description: values.description || "",
        user_id: activeUser.id,
      };

      const resultAction = await dispatch(addTransaction(payload));

      if (addTransaction.fulfilled.match(resultAction)) {
        enqueueSnackbar("Transaction successfully created!", {
          variant: "success",
        });
        closeModal();
        navigate("/create/transaction");
      } else {
        throw new Error(resultAction.error.message);
      }
    } catch (error) {
      enqueueSnackbar(error.message || "Failed to create transaction", {
        variant: "error",
      });
    }
  };

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 transition-all duration-700">
      {/* Animated background effects */}
      <div className="fixed inset-0 overflow-hidden -z-10">
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-emerald-500/5 rounded-full filter blur-3xl animate-pulse"></div>
          <div
            className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-blue-500/5 rounded-full filter blur-3xl animate-pulse"
            style={{ animationDelay: "1s" }}
          ></div>
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 h-1/2 bg-purple-500/5 rounded-full filter blur-3xl animate-pulse"
            style={{ animationDelay: "2s" }}
          ></div>
        </div>
      </div>

      <div
        className={`max-w-5xl mx-auto transition-all duration-1000 ${
          animateForm
            ? "opacity-100 transform translate-y-0"
            : "opacity-0 transform translate-y-10"
        }`}
      >
        <div className="relative bg-gradient-to-br from-slate-900/90 to-slate-800/90 backdrop-blur-xl rounded-3xl shadow-2xl p-8 border border-slate-700/50 overflow-hidden">
          {/* Animated border gradient */}
          <div className="absolute inset-0 rounded-3xl overflow-hidden">
            <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-emerald-500/50 to-transparent"></div>
            <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-blue-500/50 to-transparent"></div>
            <div className="absolute left-0 top-0 bottom-0 w-px bg-gradient-to-b from-transparent via-emerald-500/50 to-transparent"></div>
            <div className="absolute right-0 top-0 bottom-0 w-px bg-gradient-to-b from-transparent via-blue-500/50 to-transparent"></div>
          </div>

          {/* Decorative elements */}
          <div className="absolute -left-24 -top-24 w-96 h-96 bg-emerald-500/10 rounded-full blur-3xl"></div>
          <div className="absolute -right-24 -top-24 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-64 bg-gradient-to-r from-emerald-500/5 via-blue-500/5 to-purple-500/5 blur-3xl transform rotate-12"></div>

          <div className="relative">
            <div className="text-center space-y-8 py-4">
              {/* Animated badge */}
              <div className="inline-flex items-center px-4 py-2 rounded-full bg-gradient-to-r from-emerald-500/10 to-blue-500/10 border border-emerald-500/20 backdrop-blur-sm mb-4 transition-all duration-500 hover:from-emerald-500/20 hover:to-blue-500/20 shadow-lg hover:shadow-emerald-500/10 transform hover:scale-105">
                <span className="animate-pulse w-2 h-2 rounded-full bg-emerald-400 mr-2"></span>
                <span className="text-emerald-400 text-sm font-medium">
                  Transaction Management
                </span>
              </div>

              {/* Main heading with animation */}
              <div className="space-y-6">
                <h1 className="text-4xl font-extrabold">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-white to-slate-300">
                    Create Society Transaction
                  </span>
                </h1>

                {/* Society name card with animation */}
                <div className="transform transition-all duration-500 hover:scale-105 mx-auto max-w-md">
                  <div className="relative group cursor-pointer">
                    <div className="absolute -inset-1 rounded-xl bg-gradient-to-r from-emerald-500 via-teal-500 to-blue-500 opacity-30 blur group-hover:opacity-40 transition duration-300 group-hover:duration-200 animate-gradient-xy"></div>
                    <div className="relative px-8 py-5 bg-slate-900/80 ring-1 ring-slate-800/50 rounded-xl">
                      <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-emerald-400 via-teal-400 to-blue-400">
                        {activeSociety.name}
                      </h2>
                      <div className="flex items-center justify-center space-x-2 text-slate-400 mt-2 group">
                        <svg
                          className="w-4 h-4 transform transition-transform duration-300 group-hover:scale-110 text-emerald-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        <span className="text-lg transition-colors duration-300 text-slate-300">
                          {activeSociety.location}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Multi-society transaction button */}
              <div className="relative py-8">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-slate-700/50"></div>
                </div>

                <div className="relative flex justify-center">
                  <div className="px-4 bg-slate-900/0">
                    <button
                      onClick={openModal}
                      className="flex items-center space-x-2 px-6 py-3 bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-xl shadow-lg shadow-emerald-500/20 transform transition-all duration-300 hover:scale-105 hover:shadow-emerald-500/30"
                    >
                      <Zap className="w-5 h-5" />
                      <span>Create Multi-Society Transaction</span>
                    </button>
                    <TransactionModal
                      isOpen={isModalOpen}
                      closeModal={closeModal}
                      societies={societies}
                      onSubmit={handleModalSubmit}
                      modalStyle={modalStyle}
                      modalOverlayStyle={modalOverlayStyle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Error message with enhanced styling */}
          {error && (
            <div className="my-6 p-5 bg-rose-900/20 backdrop-blur-sm border border-rose-500/30 rounded-xl relative overflow-hidden group transition-all duration-300 hover:bg-rose-900/30 shadow-lg">
              <div className="absolute inset-0 bg-gradient-to-r from-rose-500/5 to-amber-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              <div className="relative flex items-center space-x-3">
                <AlertCircle className="w-6 h-6 text-rose-400 animate-pulse" />
                <span className="text-rose-300 font-medium">{error}</span>
              </div>
            </div>
          )}

          {/* Enhanced form styling */}
          <Formik
            key={formKey}
            initialValues={{
              amount: { [activeSociety._id]: "" },
              transaction_type: "",
              transaction_type_custom: "",
              transaction_direction: "",
              payment_method: "",
              description: "",
              transaction_date: null,
            }}
            validationSchema={TransactionValidationSchema}
            onSubmit={async (values, { setFieldValue, setSubmitting }) => {
              try {
                const formattedDate = values.transaction_date
                  ? format(new Date(values.transaction_date), "yyyy-MM-dd")
                  : null;

                const finalTransactionType =
                  values.transaction_type === "other"
                    ? values.transaction_type_custom
                    : values.transaction_type;

                const updatedFormData = {
                  ...values,
                  user_id: activeUser.id,
                  transaction_type: finalTransactionType,
                  societies: [activeSociety._id],
                  transaction_date: formattedDate,
                  amount: {
                    [activeSociety._id]: parseFloat(
                      values.amount[activeSociety._id]
                    ),
                  },
                };

                const resultAction = await dispatch(
                  addTransaction(updatedFormData)
                );

                if (addTransaction.fulfilled.match(resultAction)) {
                  enqueueSnackbar("Transaction successfully added!", {
                    variant: "success",
                  });
                  setFieldValue("amount", { [activeSociety._id]: "" });
                  setFieldValue("description", "");
                  navigate("/create/transaction");
                } else {
                  enqueueSnackbar("Failed to add transaction", {
                    variant: "error",
                  });
                }
              } catch (err) {
                console.error("Error creating transaction:", err);
                enqueueSnackbar("An error occurred", { variant: "error" });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, errors, touched }) => (
              <Form className="space-y-8">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                  {/* Amount Field with Icon */}
                  <div className={formSectionClass}>
                    <label htmlFor="amount" className={labelClasses}>
                      <DollarSign className="w-4 h-4 text-emerald-400" />
                      <span>Amount</span>
                    </label>
                    <Field name={`amount.${activeSociety._id}`}>
                      {({ field, form }) => (
                        <div className="relative">
                          <input
                            type="text"
                            id={`amount-${activeSociety._id}`}
                            {...field}
                            className={`${inputClasses} pl-12`}
                            placeholder="Enter amount"
                            value={
                              field.value
                                ? Number(
                                    field.value.toString().replace(/,/g, "")
                                  ).toLocaleString()
                                : ""
                            }
                            onChange={(event) => {
                              const rawValue = event.target.value.replace(
                                /,/g,
                                ""
                              );
                              if (!isNaN(rawValue) || rawValue === "") {
                                const updatedAmount = {
                                  ...form.values.amount,
                                  [activeSociety._id]: rawValue,
                                };
                                form.setFieldValue("amount", updatedAmount);
                              }
                            }}
                          />
                          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                            <span className="text-emerald-500">$</span>
                          </div>
                        </div>
                      )}
                    </Field>
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Transaction Type with enhanced select */}
                  <div className={formSectionClass}>
                    <label htmlFor="transaction_type" className={labelClasses}>
                      <Gift className="w-4 h-4 text-emerald-400" />
                      <span>Transaction Type</span>
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        id="transaction_type"
                        name="transaction_type"
                        className={`${inputClasses} cursor-pointer appearance-none`}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("transaction_type", value);
                          if (
                            value !== "other" &&
                            value !== "" &&
                            !customTransactionTypes.includes(value)
                          ) {
                            setCustomTransactionTypes([
                              ...customTransactionTypes,
                              value,
                            ]);
                          }
                        }}
                      >
                        <option value="">Select Type</option>
                        <option
                          value="Full Payment"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Full Payment
                        </option>
                        <option
                          value="Partial Payment"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Partial Payment
                        </option>
                        <option
                          value="Installment Payment"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Installment Payment
                        </option>
                        <option
                          value="Salary Payment"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Salary Payment
                        </option>
                        <option
                          value="Expense Payment"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Expense Payment
                        </option>
                        <option
                          value="Resell Payment"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Resell Payment
                        </option>
                        <option
                          value="other"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Other
                        </option>
                        {customTransactionTypes.map((type, index) => (
                          <option
                            key={index}
                            value={type}
                            className="bg-slate-800 text-emerald-300"
                          >
                            {type}
                          </option>
                        ))}
                      </Field>
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ChevronDown className="h-5 w-5 text-emerald-400" />
                      </div>
                    </div>
                    <ErrorMessage
                      name="transaction_type"
                      component="div"
                      className={errorClasses}
                    />
                    {values.transaction_type === "other" && (
                      <div className="mt-2 transition-all duration-300">
                        <Field
                          type="text"
                          id="transaction_type_custom"
                          name="transaction_type_custom"
                          className={inputClasses}
                          placeholder="Enter custom transaction type"
                          onChange={(e) => {
                            setFieldValue(
                              "transaction_type_custom",
                              e.target.value
                            );
                            if (
                              !customTransactionTypes.includes(e.target.value)
                            ) {
                              setCustomTransactionTypes([
                                ...customTransactionTypes,
                                e.target.value,
                              ]);
                            }
                          }}
                        />
                        <ErrorMessage
                          name="transaction_type_custom"
                          component="div"
                          className={errorClasses}
                        />
                      </div>
                    )}
                  </div>

                  {/* Transaction Direction */}
                  <div className={formSectionClass}>
                    <label
                      htmlFor="transaction_direction"
                      className={labelClasses}
                    >
                      <ChevronUp className="w-4 h-4 text-emerald-400" />
                      <span>Transaction Direction</span>
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        id="transaction_direction"
                        name="transaction_direction"
                        className={`${inputClasses} cursor-pointer appearance-none`}
                      >
                        <option value="">Select Direction</option>
                        <option
                          value="Income"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Income
                        </option>
                        <option
                          value="Expense"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Expense
                        </option>
                      </Field>
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ChevronDown className="h-5 w-5 text-emerald-400" />
                      </div>
                    </div>
                    <ErrorMessage
                      name="transaction_direction"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Payment Method */}
                  <div className={formSectionClass}>
                    <label htmlFor="payment_method" className={labelClasses}>
                      <CreditCard className="w-4 h-4 text-emerald-400" />
                      <span>Payment Method</span>
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        id="payment_method"
                        name="payment_method"
                        className={`${inputClasses} cursor-pointer appearance-none`}
                      >
                        <option value="">Select Payment Method</option>
                        <option
                          value="Bank Transfer"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Bank Transfer
                        </option>
                        <option
                          value="Cash Transfer"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Cash Transfer
                        </option>
                        <option
                          value="JazzCash"
                          className="bg-slate-800 text-emerald-300"
                        >
                          JazzCash
                        </option>
                        <option
                          value="Easypaisa"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Easypaisa
                        </option>
                        <option
                          value="UPaisa"
                          className="bg-slate-800 text-emerald-300"
                        >
                          UPaisa
                        </option>
                        <option
                          value="SadaPay"
                          className="bg-slate-800 text-emerald-300"
                        >
                          SadaPay
                        </option>
                        <option
                          value="NayaPay"
                          className="bg-slate-800 text-emerald-300"
                        >
                          NayaPay
                        </option>
                        <option
                          value="PayPak"
                          className="bg-slate-800 text-emerald-300"
                        >
                          PayPak
                        </option>
                        <option
                          value="Other"
                          className="bg-slate-800 text-emerald-300"
                        >
                          Other
                        </option>
                      </Field>
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ChevronDown className="h-5 w-5 text-emerald-400" />
                      </div>
                    </div>
                    <ErrorMessage
                      name="payment_method"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Date Picker */}
                  <div className="bg-slate-800/20 backdrop-blur-md rounded-2xl p-6 border border-slate-700/30 shadow-xl transition-all duration-500 hover:shadow-emerald-900/10 relative z-10">
                    <label htmlFor="transaction_date" className={labelClasses}>
                      <Calendar className="w-4 h-4 text-emerald-400" />
                      <span>Date</span>
                    </label>
                    <div className="w-full">
                      <Field name="transaction_date">
                        {({ field, form }) => (
                          <TransactionDatePicker field={field} form={form} />
                        )}
                      </Field>
                    </div>
                    <ErrorMessage
                      name="transaction_date"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Description - Full Width */}
                  <div className={`md:col-span-2 ${formSectionClass}`}>
                    <label htmlFor="description" className={labelClasses}>
                      <FileText className="w-4 h-4 text-emerald-400" />
                      <span>Description</span>
                    </label>
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      rows="4"
                      className={`${inputClasses} resize-none`}
                      placeholder="Enter additional details about this transaction"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Submit Button - Full Width */}
                  <div className="md:col-span-2 flex justify-center mt-6">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`w-full md:w-1/2 py-4 px-6 bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-xl shadow-lg shadow-emerald-500/20 transform transition-all duration-300 hover:scale-105 hover:shadow-emerald-500/30 font-medium text-lg flex items-center justify-center ${
                        isSubmitting ? "opacity-70 cursor-not-allowed" : ""
                      }`}
                    >
                      {isSubmitting ? (
                        <div className="flex items-center space-x-2">
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <span>Processing...</span>
                        </div>
                      ) : (
                        <span>Create Transaction</span>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TransactionCreate;
