import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchTransactions,
  modifyTransaction,
  removeTransaction
} from '../store/slices/transactionSlice'
import Swal from 'sweetalert2'
import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'
import {
  ArrowDownUp,
  ChevronLeft,
  ChevronRight,
  Edit,
  Search,
  Trash2,
  X,
  Filter,
  Download,
  ChevronsRight,
  ChevronsLeft
} from 'lucide-react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDOMServer from 'react-dom/server'
import { createRoot } from 'react-dom/client'
import EnhancedDateFilter from './EnhancedDateFilter'
import ExportExcel from './ExportExcel'

const ExpenseIncomeTrack = () => {
  const dispatch = useDispatch()
  const { activeSociety } = useSidebarContext()
  const transactionsData = useSelector(state => state.transactions)
  const activeUser = useSelector(state => state.auth.activeUser)

  // State management
  const [filters, setFilters] = useState({
    transactionType: '',
    amount: '',
    description: '',
    startDate: '',
    endDate: '',
    transactionDirection: ''
  })
  const [sortOrder, setSortOrder] = useState('desc')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [dateRange, setDateRange] = useState([null, null])
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [totalFilteredAmount, setTotalFilteredAmount] = useState(0)
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)

  const isEmployee = activeUser?.role === 'Employee'

  useEffect(() => {
    if (activeSociety?._id) {
      dispatch(
        fetchTransactions({
          societyId: activeSociety._id,
          page: currentPage,
          limit: itemsPerPage,
          description: filters.description,
          startDate: filters.startDate
            ? new Date(filters.startDate).toISOString()
            : '',
          endDate: filters.endDate
            ? new Date(filters.endDate).toISOString()
            : '',
          transactionType: filters.transactionType,
          transactionDirection: filters.transactionDirection,
          sortOrder
        })
      )
    }
  }, [
    dispatch,
    activeSociety,
    currentPage,
    itemsPerPage,
    filters.description,
    filters.startDate,
    filters.endDate,
    filters.transactionType,
    filters.transactionDirection,
    sortOrder
  ])

  const transactions = useMemo(() => {
    if (transactionsData?.transactions?.data?.transactions) {
      return transactionsData.transactions.data.transactions.slice(
        0,
        itemsPerPage
      )
    }
    return []
  }, [transactionsData, itemsPerPage])

  useEffect(() => {
    const filtered = transactions.filter(transaction => {
      if (
        filters.description &&
        !transaction.description
          ?.toLowerCase()
          .includes(filters.description.toLowerCase())
      ) {
        return false
      }

      if (filters.startDate && filters.endDate) {
        const transactionDate = new Date(transaction.transaction_date)
        const startDate = new Date(filters.startDate)
        startDate.setHours(0, 0, 0, 0)
        const endDate = new Date(filters.endDate)
        endDate.setHours(23, 59, 59, 999)
        if (transactionDate < startDate || transactionDate > endDate) {
          return false
        }
      }
      if (
        filters.transactionDirection &&
        transaction.transaction_direction !== filters.transactionDirection
      ) {
        return false
      }

      if (
        filters.transactionType &&
        transaction.transaction_type !== filters.transactionType
      ) {
        return false
      }

      return true
    })
    const sorted = [...filtered].sort((a, b) => {
      const dateA = new Date(a.transaction_date)
      const dateB = new Date(b.transaction_date)
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA
    })

    setFilteredTransactions(sorted)
    const total = filtered.reduce((sum, transaction) => {
      if (transaction.transaction_direction === 'in') {
        return sum + (transaction.amount || 0)
      } else if (transaction.transaction_direction === 'out') {
        return sum - (transaction.amount || 0)
      }
      return sum
    }, 0)

    setTotalFilteredAmount(total)
  }, [transactions, filters, sortOrder])

  const totalFilteredAmounts = useMemo(() => {
    if (!Array.isArray(filteredTransactions)) return 0

    return filteredTransactions.reduce(
      (sum, transaction) => sum + Number(transaction.amount || 0),
      0
    )
  }, [filteredTransactions])

  const totalIncome = useMemo(() => {
    if (transactionsData?.transactions?.data?.summary?.totalIncome) {
      return transactionsData.transactions.data.summary.totalIncome
    }
    return 0
  }, [transactionsData])

  const totalExpenses = useMemo(() => {
    if (transactionsData?.transactions?.data?.summary?.totalExpense) {
      return transactionsData.transactions.data.summary.totalExpense
    }
    return 0
  }, [transactionsData])

  // Group transactions by type for calculations
  const paymentIncome = transactions.filter(
    t => t.transaction_direction === 'Income'
  )

  const salaryExpenses = transactions.filter(
    t =>
      t.transaction_type === 'Salary Payment' &&
      t.transaction_direction === 'Expense'
  )

  const dealerExpenses = transactions.filter(
    t =>
      t.transaction_type === 'Dealer Commission' &&
      t.transaction_direction === 'Expense'
  )

  const totalSalary = calculateTotal(salaryExpenses)
  const totalDealer = calculateTotal(dealerExpenses)

  // Navigation handling
  const handleNextPage = () => {
    if (
      transactionsData?.transactions?.data?.total >
      currentPage * itemsPerPage
    ) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleFirstPage = () => {
    setCurrentPage(1)
  }

  const handleLastPage = () => {
    const totalItems = transactionsData?.transactions?.data?.total || 0
    const totalPages = Math.ceil(totalItems / itemsPerPage)
    setCurrentPage(totalPages)
  }

  const handleSortOrderChange = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'))
  }

  // Utility functions
  function calculateTotal (transactions) {
    return transactions.reduce((sum, t) => sum + Number(t.amount || 0), 0)
  }

  const clearFilters = () => {
    setFilters({
      transactionType: '',
      description: '',
      transactionDirection: '',
      amount: '',
      startDate: '',
      endDate: ''
    })
    setDateRange([null, null])
    setCurrentPage(1)
  }

  const getTransactionTypeColor = (transactionType, direction) => {
    // Base styles for all rows
    let baseStyle = 'transition-colors duration-300 hover:bg-slate-700/60 '

    // Direction-based styling (Income vs Expense)
    if (direction === 'Income') {
      baseStyle += 'border-l-4 border-emerald-500'
    } else if (direction === 'Expense') {
      baseStyle += 'border-l-4 border-red-500 '
    }

    // Type-specific styling
    const colors = {
      'Full Payment': 'bg-gradient-to-r from-green-500/10 to-green-500/5',
      'Partial Payment': 'bg-gradient-to-r from-green-500/10 to-green-500/5',
      'Installment Payment':
        'bg-gradient-to-r from-purple-500/10 to-purple-500/5',
      'Salary Payment': 'bg-gradient-to-r from-yellow-500/10 to-yellow-500/5',
      'Expense Payment': 'bg-gradient-to-r from-red-500/10 to-red-500/5',
      'Resell Payment': 'bg-gradient-to-r from-indigo-500/10 to-indigo-500/5',
      'Dealer Commission':
        'bg-gradient-to-r from-orange-500/10 to-orange-500/5',
      'Booking Full Payment': 'bg-gradient-to-r from-cyan-500/10 to-cyan-500/5',
      'Booking Installment Payment':
        'bg-gradient-to-r from-cyan-500/10 to-cyan-500/5',
      'Transfer Fee': 'bg-gradient-to-r from-pink-500/10 to-pink-500/5'
    }

    return (
      baseStyle +
      (colors[transactionType] ||
        'bg-gradient-to-r from-teal-500/10 to-teal-500/5')
    )
  }

  // Edit transaction handler
  const handleEdit = transaction => {
    if (isEmployee) return

    // Create a ref to store the selected date
    const dateRef = React.createRef()

    const CustomDialog = () => {
      // Local state for the date
      const [dateValue, setDateValue] = React.useState(
        new Date(transaction.transaction_date)
      )
      return (
        <div className='max-w-3xl mx-auto p-6'>
          <div className='bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 rounded-xl shadow-2xl border border-slate-700'>
            <h2 className='text-3xl font-bold text-teal-300 mb-6'>
              Edit Transaction
            </h2>
            <div className='grid grid-cols-2 gap-6'>
              {[
                {
                  id: 'amount',
                  label: 'Amount',
                  type: 'number',
                  value: transaction.amount
                },
                {
                  id: 'transaction_type',
                  label: 'Transaction Type',
                  type: 'text',
                  value: transaction.transaction_type
                },
                {
                  id: 'transaction_direction',
                  label: 'Transaction Direction',
                  type: 'text',
                  value: transaction.transaction_direction
                },
                {
                  id: 'payment_method',
                  label: 'Payment Method',
                  type: 'text',
                  value: transaction.payment_method
                },
                {
                  id: 'status',
                  label: 'Status',
                  type: 'text',
                  value: transaction.status
                },
                {
                  id: 'description',
                  label: 'Description',
                  type: 'text',
                  value: transaction.description
                },
                {
                  id: 'receipt_no',
                  label: 'Receipt No',
                  type: 'text',
                  value: transaction.receipt_no
                }
              ].map(field => (
                <div key={field.id} className='relative'>
                  <label className='block text-teal-300 text-sm font-medium mb-2'>
                    {field.label}
                  </label>
                  <input
                    id={field.id}
                    type={field.type}
                    className='w-full px-4 py-2.5 rounded-lg border border-slate-600 bg-slate-700 text-white
                               placeholder-slate-400 focus:border-teal-400 focus:ring-2 focus:ring-teal-400
                               focus:ring-opacity-50 transition-all duration-200 ease-in-out'
                    defaultValue={field.value}
                  />
                </div>
              ))}
              <div className='relative'>
                <label className='block text-teal-300 text-sm font-medium mb-2'>
                  Transaction Date
                </label>
                <DatePicker
                  selected={dateValue}
                  onChange={date => {
                    setDateValue(date)
                    dateRef.current = date
                  }}
                  dateFormat='dd/MM/yyyy'
                  placeholderText='DD/MM/YYYY'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className='w-full px-4 py-2.5 rounded-lg border border-slate-600 bg-slate-700 text-white
                             placeholder-slate-400 focus:border-teal-400 focus:ring-2 focus:ring-teal-400
                             focus:ring-opacity-50 transition-all duration-200 ease-in-out'
                />
              </div>
            </div>
          </div>
        </div>
      )
    }

    Swal.fire({
      html: ReactDOMServer.renderToString(<CustomDialog />),
      showCancelButton: true,
      confirmButtonText: 'Save Changes',
      cancelButtonText: 'Cancel',
      width: 'auto',
      background: '#1E293B',
      customClass: {
        popup:
          'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl text-teal-400',
        confirmButton:
          'bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200',
        cancelButton:
          'bg-red-500 hover:bg-red-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200',
        title: 'text-2xl font-bold text-teal-300 mb-4',
        container: 'backdrop-blur-sm'
      },
      didOpen: () => {
        const container = document.querySelector('.swal2-html-container')
        if (container) {
          // Clear any previous content
          container.innerHTML = ''

          // Create root and render the component
          const root = createRoot(container)
          root.render(<CustomDialog />)

          // Save root instance for cleanup
          container._rootInstance = root
        }
      },
      willClose: () => {
        const container = document.querySelector('.swal2-html-container')
        if (container && container._rootInstance) {
          // Unmount the component
          container._rootInstance.unmount()
        }
      },
      preConfirm: () => {
        return {
          transaction_date: dateRef.current
            ? dateRef.current.toISOString()
            : transaction.transaction_date,
          amount: document.getElementById('amount').value,
          transaction_type: document.getElementById('transaction_type').value,
          transaction_direction: document.getElementById(
            'transaction_direction'
          ).value,
          payment_method: document.getElementById('payment_method').value,
          status: document.getElementById('status').value,
          description: document.getElementById('description').value,
          receipt_no: document.getElementById('receipt_no').value
        }
      }
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          modifyTransaction({
            id: transaction._id,
            transactionData: {
              ...transaction,
              ...result.value
            }
          })
        )

        Swal.fire({
          title: 'Success!',
          text: 'Transaction has been updated successfully',
          icon: 'success',
          confirmButtonColor: '#10B981',
          background: '#1E293B',
          color: '#5EEAD4',
          confirmButtonText: 'Done',
          customClass: {
            popup:
              'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl',
            confirmButton:
              'hover:bg-emerald-600 transition-colors px-6 py-2.5 rounded-lg',
            title: 'text-xl font-bold text-teal-300'
          }
        })
      }
    })
  }

  // Delete transaction handler
  const handleDelete = transactionId => {
    if (isEmployee) return
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      background: '#1E293B',
      customClass: {
        popup:
          'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl text-teal-400',
        confirmButton:
          'bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200',
        cancelButton:
          'bg-red-500 hover:bg-red-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200',
        title: 'text-2xl font-bold text-teal-300 mb-4',
        container: 'backdrop-blur-sm'
      }
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(removeTransaction(transactionId))

        Swal.fire({
          title: 'Deleted!',
          text: 'The transaction has been deleted.',
          icon: 'success',
          confirmButtonColor: '#10B981',
          background: '#1E293B',
          color: '#5EEAD4',
          confirmButtonText: 'Done',
          customClass: {
            popup:
              'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl',
            confirmButton:
              'hover:bg-emerald-600 transition-colors px-6 py-2.5 rounded-lg',
            title: 'text-xl font-bold text-teal-300'
          }
        })
      }
    })
  }

  // Pagination component
  const renderPagination = () => {
    const totalItems = transactionsData?.transactions?.data?.total || 0
    const totalPages = Math.ceil(totalItems / itemsPerPage)

    // Create array of page numbers to display
    let pageNumbers = []
    if (totalPages <= 7) {
      pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1)
    } else {
      if (currentPage <= 3) {
        pageNumbers = [1, 2, 3, 4, 5, '...', totalPages]
      } else if (currentPage >= totalPages - 2) {
        pageNumbers = [
          1,
          '...',
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        ]
      } else {
        pageNumbers = [
          1,
          '...',
          currentPage - 1,
          currentPage,
          currentPage + 1,
          '...',
          totalPages
        ]
      }
    }

    return (
      <div className='flex items-center justify-between mt-4'>
        <div className='flex items-center space-x-2'>
          <button
            onClick={handleFirstPage}
            disabled={currentPage === 1}
            className={`flex items-center justify-center h-9 w-9 rounded-lg transition-all duration-300 ${
              currentPage === 1
                ? 'bg-slate-700/50 text-slate-500 cursor-not-allowed'
                : 'bg-slate-700 text-teal-300 hover:bg-slate-600'
            }`}
            aria-label='First page'
          >
            <ChevronsLeft className='h-5 w-5' />
          </button>

          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`flex items-center justify-center h-9 w-9 rounded-lg transition-all duration-300 ${
              currentPage === 1
                ? 'bg-slate-700/50 text-slate-500 cursor-not-allowed'
                : 'bg-slate-700 text-teal-300 hover:bg-slate-600'
            }`}
            aria-label='Previous page'
          >
            <ChevronLeft className='h-5 w-5' />
          </button>

          <div className='flex items-center space-x-1'>
            {pageNumbers.map((pageNum, index) =>
              pageNum === '...' ? (
                <span key={`ellipsis-${index}`} className='px-2 text-slate-400'>
                  ...
                </span>
              ) : (
                <button
                  key={pageNum}
                  onClick={() => setCurrentPage(pageNum)}
                  className={`flex items-center justify-center h-9 w-9 rounded-lg text-sm font-medium transition-all duration-300 ${
                    pageNum === currentPage
                      ? 'bg-teal-500 text-white shadow-xl scale-105'
                      : 'bg-slate-700/80 text-teal-300 hover:bg-slate-600'
                  }`}
                >
                  {pageNum}
                </button>
              )
            )}
          </div>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages || totalPages === 0}
            className={`flex items-center justify-center h-9 w-9 rounded-lg transition-all duration-300 ${
              currentPage === totalPages || totalPages === 0
                ? 'bg-slate-700/50 text-slate-500 cursor-not-allowed'
                : 'bg-slate-700 text-teal-300 hover:bg-slate-600'
            }`}
            aria-label='Next page'
          >
            <ChevronRight className='h-5 w-5' />
          </button>

          <button
            onClick={handleLastPage}
            disabled={currentPage === totalPages || totalPages === 0}
            className={`flex items-center justify-center h-9 w-9 rounded-lg transition-all duration-300 ${
              currentPage === totalPages || totalPages === 0
                ? 'bg-slate-700/50 text-slate-500 cursor-not-allowed'
                : 'bg-slate-700 text-teal-300 hover:bg-slate-600'
            }`}
            aria-label='Last page'
          >
            <ChevronsRight className='h-5 w-5' />
          </button>
        </div>

        <div className='flex items-center space-x-3'>
          <span className='text-sm text-slate-400'>
            Showing{' '}
            <span className='font-medium text-teal-300'>
              {totalItems > 0
                ? Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)
                : 0}
            </span>{' '}
            to{' '}
            <span className='font-medium text-teal-300'>
              {Math.min(currentPage * itemsPerPage, totalItems)}
            </span>{' '}
            of <span className='font-medium text-teal-300'>{totalItems}</span>{' '}
            entries
          </span>

          <select
            value={itemsPerPage}
            onChange={e => {
              setItemsPerPage(Number(e.target.value))
              setCurrentPage(1) // Reset to first page when changing items per page
            }}
            className='bg-slate-700 border border-slate-600 text-teal-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-teal-400 focus:border-teal-400 text-sm'
          >
            {[10, 20, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} per page
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
  return (
    <div className='bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-4 md:p-8'>
      <div className='max-w-[1225px] mx-auto space-y-4'>
        {/* Header */}
        <div className='flex flex-col md:flex-row md:items-center justify-between gap-4'>
          <div>
            {activeSociety && (
              <div className='flex items-center gap-3'>
                <div className='w-16 h-16 rounded-xl overflow-hidden border-2 border-teal-400 shadow-lg shadow-teal-400/20'>
                  <img
                    src={
                      activeSociety.society_image || '/api/placeholder/40/40'
                    }
                    alt={activeSociety.name}
                    className='w-full h-full object-cover'
                    onError={e => {
                      e.target.src = '/api/placeholder/40/40'
                    }}
                  />
                </div>
                <div className='text-left'>
                  <p className='text-3xl text-teal-400 font-bold'>
                    {activeSociety.name}
                  </p>
                  <h2 className='text-2xl md:text-3xl font-bold bg-gradient-to-r from-teal-300 to-teal-500 bg-clip-text text-transparent'>
                    Financial Dashboard
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Stats Cards */}
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
          {/* Total Income Card */}
          <div className='bg-gradient-to-br from-slate-800/80 to-slate-900/80 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700/50 overflow-hidden group hover:border-teal-500/30 transition-all duration-300 hover:shadow-teal-500/10'>
            <div className='p-6'>
              <div className='flex items-center justify-between'>
                <div className='flex-1'>
                  <p className='text-sm font-medium text-slate-400 mb-1'>
                    Total Income
                  </p>
                  <p className='text-2xl font-bold bg-gradient-to-r from-teal-300 to-teal-400 bg-clip-text text-transparent'>
                    {totalIncome.toLocaleString()} PKR
                  </p>
                </div>
                <div className='p-3 bg-teal-500/10 rounded-xl group-hover:bg-teal-500/20 transition-all duration-300'>
                  <div className='w-10 h-10 flex items-center justify-center text-teal-400 group-hover:text-teal-300'>
                    📈
                  </div>
                </div>
              </div>
            </div>
            <div className='h-1 w-full bg-gradient-to-r from-teal-500 to-green-500'></div>
          </div>

          {/* Salary Expenses Card */}
          <div className='bg-gradient-to-br from-slate-800/80 to-slate-900/80 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700/50 overflow-hidden group hover:border-yellow-500/30 transition-all duration-300 hover:shadow-yellow-500/10'>
            <div className='p-6'>
              <div className='flex items-center justify-between'>
                <div className='flex-1'>
                  <p className='text-sm font-medium text-slate-400 mb-1'>
                    Salary Expenses
                  </p>
                  <p className='text-2xl font-bold bg-gradient-to-r from-yellow-300 to-yellow-400 bg-clip-text text-transparent'>
                    {totalSalary.toLocaleString()} PKR
                  </p>
                </div>
                <div className='p-3 bg-yellow-500/10 rounded-xl group-hover:bg-yellow-500/20 transition-all duration-300'>
                  <div className='w-10 h-10 flex items-center justify-center text-yellow-400 group-hover:text-yellow-300'>
                    💼
                  </div>
                </div>
              </div>
            </div>
            <div className='h-1 w-full bg-gradient-to-r from-yellow-500 to-orange-500'></div>
          </div>

          {/* Dealer Commission Card */}
          <div className='bg-gradient-to-br from-slate-800/80 to-slate-900/80 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700/50 overflow-hidden group hover:border-orange-500/30 transition-all duration-300 hover:shadow-orange-500/10'>
            <div className='p-6'>
              <div className='flex items-center justify-between'>
                <div className='flex-1'>
                  <p className='text-sm font-medium text-slate-400 mb-1'>
                    Dealer Commission
                  </p>
                  <p className='text-2xl font-bold bg-gradient-to-r from-orange-300 to-orange-400 bg-clip-text text-transparent'>
                    {totalDealer.toLocaleString()} PKR
                  </p>
                </div>
                <div className='p-3 bg-orange-500/10 rounded-xl group-hover:bg-orange-500/20 transition-all duration-300'>
                  <div className='w-10 h-10 flex items-center justify-center text-orange-400 group-hover:text-orange-300'>
                    📊
                  </div>
                </div>
              </div>
            </div>
            <div className='h-1 w-full bg-gradient-to-r from-orange-500 to-red-500'></div>
          </div>

          {/* Total Expenses Card */}
          <div className='bg-gradient-to-br from-slate-800/80 to-slate-900/80 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700/50 overflow-hidden group hover:border-red-500/30 transition-all duration-300 hover:shadow-red-500/10'>
            <div className='p-6'>
              <div className='flex items-center justify-between'>
                <div className='flex-1'>
                  <p className='text-sm font-medium text-slate-400 mb-1'>
                    Total Expenses
                  </p>
                  <p className='text-2xl font-bold bg-gradient-to-r from-red-300 to-red-400 bg-clip-text text-transparent'>
                    {totalExpenses.toLocaleString()} PKR
                  </p>
                </div>
                <div className='p-3 bg-red-500/10 rounded-xl group-hover:bg-red-500/20 transition-all duration-300'>
                  <div className='w-10 h-10 flex items-center justify-center text-red-400 group-hover:text-red-300'>
                    📉
                  </div>
                </div>
              </div>
            </div>
            <div className='h-1 w-full bg-gradient-to-r from-red-500 to-pink-500'></div>
          </div>
        </div>

        {/* Search and Filters */}
        <div className='space-y-3'>
          <div className='flex flex-col md:flex-row items-stretch md:items-center justify-between gap-4'>
            {/* Search Bar */}
            <div className='relative flex-1'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <Search size={18} className='text-slate-400' />
              </div>
              <input
                type='text'
                value={filters.description}
                onChange={e =>
                  setFilters({ ...filters, description: e.target.value })
                }
                placeholder='Search transactions by description...'
                className='bg-slate-800 text-white placeholder-slate-400 border border-slate-700 rounded-lg py-2 pl-10 pr-4 w-full focus:ring-2 focus:ring-teal-400 focus:border-teal-400 transition-all duration-200'
              />
            </div>

            {/* Actions */}
            <div className='flex flex-wrap items-center gap-2'>
              <button
                onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                className='flex items-center space-x-2 px-4 py-2 bg-slate-700 hover:bg-slate-600 text-white rounded-lg transition-colors duration-200'
              >
                <Filter size={18} />
                <span>
                  {showAdvancedFilters ? 'Hide Filters' : 'Advanced Filters'}
                </span>
              </button>

              <button
                onClick={handleSortOrderChange}
                className='flex items-center space-x-2 px-4 py-2 bg-slate-700 hover:bg-slate-600 text-white rounded-lg transition-colors duration-200'
              >
                <ArrowDownUp size={18} />
                <span>
                  {sortOrder === 'asc' ? 'Oldest First' : 'Latest First'}
                </span>
              </button>

              <ExportExcel
                activeSociety={activeSociety}
                filteredTransactions={filteredTransactions}
                filters={filters}
                dispatch={dispatch}
                fetchTransactions={fetchTransactions}
                sortOrder={sortOrder}
              />
            </div>
          </div>

          {/* Advanced Filters Panel */}
          {showAdvancedFilters && (
            <div className='bg-slate-800 rounded-xl p-4 border border-slate-700 shadow-lg animate-fadeIn'>
              <div className='flex flex-col md:flex-row md:items-center gap-4'>
                {/* Transaction Direction Filter */}
                <div className='flex-1'>
                  <label className='block text-sm font-medium text-slate-300 mb-1'>
                    Direction
                  </label>
                  <select
                    value={filters.transactionDirection}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        transactionDirection: e.target.value
                      })
                      setCurrentPage(1)
                    }}
                    className='w-full bg-slate-700 border border-slate-600 text-white rounded-lg px-3 py-2 focus:ring-2 focus:ring-teal-400 focus:border-teal-400'
                  >
                    <option value=''>All</option>
                    <option value='Income'>Income</option>
                    <option value='Expense'>Expense</option>
                  </select>
                </div>

                {/* Transaction Type Filter */}
                <div className='flex-1'>
                  <label className='block text-sm font-medium text-slate-300 mb-1'>
                    Type
                  </label>
                  <select
                    value={filters.transactionType}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        transactionType: e.target.value
                      })
                      setCurrentPage(1)
                    }}
                    className='w-full bg-slate-700 border border-slate-600 text-white rounded-lg px-3 py-2 focus:ring-2 focus:ring-teal-400 focus:border-teal-400'
                  >
                    <option value=''>All Types</option>
                    <option value='Full Payment'>Full Payment</option>
                    <option value='Partial Payment'>Partial Payment</option>
                    <option value='Installment Payment'>
                      Installment Payment
                    </option>
                    <option value='Salary Payment'>Salary Payment</option>
                    <option value='Expense Payment'>Expense Payment</option>
                    <option value='Resell Payment'>Resell Payment</option>
                    <option value='Dealer Commission'>Dealer Commission</option>
                    <option value='Booking Full Payment'>
                      Booking Full Payment
                    </option>
                    <option value='Booking Installment Payment'>
                      Booking Installment Payment
                    </option>
                    <option value='Transfer Fee'>Transfer Fee</option>
                  </select>
                </div>

                {/* Date Range */}
                <div className='flex-1'>
                  <label className='block text-sm font-medium text-slate-300 mb-1'>
                    Date Range
                  </label>
                  <EnhancedDateFilter
                    onDateRangeChange={dateRange => {
                      setDateRange(dateRange)
                      // Format dates correctly for API consumption
                      setFilters(prevFilters => ({
                        ...prevFilters,
                        startDate: dateRange[0] ? dateRange[0] : '',
                        endDate: dateRange[1] ? dateRange[1] : ''
                      }))
                      setCurrentPage(1) // Reset to first page when date filter changes
                    }}
                    initialStartDate={filters.startDate}
                    initialEndDate={filters.endDate}
                  />
                </div>

                {/* Clear Filters Button */}
                <div className='self-end py-1'>
                  <button
                    onClick={clearFilters}
                    className='px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors duration-200 flex items-center space-x-1'
                  >
                    <X size={16} />
                    <span>Clear</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Transactions Table */}
        <div className='bg-slate-800/60 backdrop-blur-sm rounded-xl overflow-hidden border border-slate-700/50 shadow-lg'>
          <div className='overflow-y-auto'>
            <table className='min-w-full divide-y divide-slate-700'>
              <thead className='bg-slate-800'>
                <tr>
                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Plot no.
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Date
                  </th>

                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Type
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Direction
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Description
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Method
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-right text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Amount
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-center text-xs font-medium text-teal-300 uppercase tracking-wider'
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-slate-700'>
                {filteredTransactions.length > 0 ? (
                  filteredTransactions.map((transaction, index) => (
                    <tr
                      key={transaction._id || index}
                      className={getTransactionTypeColor(
                        transaction.transaction_type,
                        transaction.transaction_direction
                      )}
                    >
                      <td className='px-4 py-3 whitespace-nowrap text-sm text-slate-200 w-[150px] break-words'>
                        {transaction.transaction_type === 'Salary Payment' ? (
                          <>
                            Employee
                            <br />
                            <span className='text-md font-semibold'>
                              {transaction.employee_id?.name ||
                                transaction.employee_info[0]?.name}
                            </span>
                          </>
                        ) : transaction.transaction_type === '' ? (
                          <>
                            Investor
                            <br />
                            <span className='text-md font-semibold'>
                              {transaction.users?.investorData?.name || (
                                <i>No Investor</i>
                              )}
                            </span>
                          </>
                        ) : transaction.transaction_type ===
                          'Dealer Commission' ? (
                          <>
                            Dealer name
                            <br />
                            <span className='text-md font-semibold'>
                              {transaction.booking_id?.dealer_name || (
                                <i>No Dealer</i>
                              )}
                            </span>
                            <br />
                            <span className='text-md font-semibold'>
                              {transaction.booking_id?.dealer_phone || (
                                <i>No Dealer</i>
                              )}
                            </span>
                          </>
                        ) : transaction.customer_id?.name ||
                          (transaction.customer_info &&
                            transaction.customer_info[0]?.name) ? (
                          <>
                            Customer
                            <br />
                            <span className='text-md font-semibold'>
                              {transaction.customer_id?.name ||
                                transaction.customer_info[0]?.name}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className='text-md font-semibold'>
                              <i>No user</i>
                            </span>
                          </>
                        )}
                      </td>
                      <td className='px-4 py-3 whitespace-nowrap text-sm text-slate-200 flex flex-col'>
                        {transaction.plot_id?.plot_number ||
                        (transaction.plot_info &&
                          transaction.plot_info[0]?.plot_number) ? (
                          <span>
                            Plot No:{' '}
                            {transaction.plot_id?.plot_number ||
                              transaction.plot_info[0]?.plot_number}
                          </span>
                        ) : null}

                        {transaction.receipt_no ? (
                          <span className='px-1 py-0.5 bg-yellow-800/20 text-yellow-400 rounded-md'>
                            Receipt# {transaction.receipt_no}
                          </span>
                        ) : null}

                        {transaction.booking_info &&
                        transaction.booking_info[0]?.offertag ? (
                          <div className='relative -bottom-1 -right-0'>
                            <div className='bg-red-600 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-lg flex items-center space-x-1'>
                              <span>
                                {transaction.booking_info[0].offertag}
                              </span>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-3 w-3'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                              >
                                <path
                                  fillRule='evenodd'
                                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z'
                                  clipRule='evenodd'
                                />
                              </svg>
                            </div>
                          </div>
                        ) : null}

                        {/* If nothing above is rendered, show N/A */}
                        {!(
                          transaction.plot_id?.plot_number ||
                          transaction.plot_info?.[0]?.plot_number ||
                          transaction.receipt_no ||
                          transaction.booking_info?.[0]?.offertag
                        ) && <span className='text-slate-400 italic'>N/A</span>}
                      </td>

                      <td className='px-4 py-3 whitespace-nowrap text-sm text-slate-200'>
                        {new Date(
                          transaction.transaction_date
                        ).toLocaleDateString()}
                      </td>

                      <td className='px-4 py-3 whitespace-nowrap text-sm'>
                        <span className='px-2 py-1 text-xs font-medium rounded-md bg-slate-700/70 text-teal-300'>
                          {transaction.transaction_type}
                        </span>
                      </td>
                      <td className='px-4 py-3 whitespace-nowrap text-sm'>
                        <span
                          className={`px-2 py-1 text-xs font-medium rounded-md ${
                            transaction.transaction_direction === 'Income'
                              ? 'bg-green-700/70 text-green-300'
                              : 'bg-red-700/70 text-red-300'
                          }`}
                        >
                          {transaction.transaction_direction}
                        </span>
                      </td>

                      <td className='px-4 py-3 text-sm text-slate-200 max-w-[200px] break-words truncate'>
                        {transaction.description || '-'}
                      </td>
                      <td className='px-4 py-3 whitespace-nowrap text-sm text-slate-200'>
                        {transaction.payment_method || '-'}
                      </td>
                      <td className='px-4 py-3 whitespace-nowrap'>
                        <span
                          className={`px-2 py-1 text-xs font-medium rounded-md ${
                            transaction.status === 'Completed'
                              ? 'bg-green-800/20 text-green-400'
                              : transaction.status === 'pending'
                              ? 'bg-yellow-800/20 text-yellow-400'
                              : 'bg-red-800/20 text-red-400'
                          }`}
                        >
                          {transaction.status}
                        </span>
                      </td>
                      <td className='px-4 py-3 whitespace-nowrap text-right text-sm font-medium'>
                        <span
                          className={
                            transaction.transaction_direction === 'Income'
                              ? 'text-emerald-400'
                              : 'text-red-400'
                          }
                        >
                          {transaction.transaction_direction === 'Income'
                            ? '+'
                            : '-'}
                          {transaction.amount?.toLocaleString() || '0'} PKR
                        </span>
                      </td>
                      <td className='px-4 py-3 whitespace-nowrap text-sm text-center'>
                        <div className='flex justify-center space-x-2'>
                          <button
                            onClick={() => handleEdit(transaction)}
                            disabled={isEmployee}
                            className={`p-1.5 rounded-lg ${
                              isEmployee
                                ? 'bg-slate-700/50 text-slate-500 cursor-not-allowed'
                                : 'bg-teal-500/10 text-teal-400 hover:bg-teal-500/20 hover:text-teal-300'
                            } transition-colors duration-200`}
                            title='Edit'
                          >
                            <Edit size={16} />
                          </button>
                          <button
                            onClick={() => handleDelete(transaction._id)}
                            disabled={isEmployee}
                            className={`p-1.5 rounded-lg ${
                              isEmployee
                                ? 'bg-slate-700/50 text-slate-500 cursor-not-allowed'
                                : 'bg-red-500/10 text-red-400 hover:bg-red-500/20 hover:text-red-300'
                            } transition-colors duration-200`}
                            title='Delete'
                          >
                            <Trash2 size={16} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan='12'
                      className='px-4 py-8 text-center text-lg text-slate-400'
                    >
                      {transactionsData.loading ? (
                        'Loading transactions...'
                      ) : (
                        <>
                          <div className='flex flex-col items-center justify-center space-y-3'>
                            <div className='text-5xl'>🔍</div>
                            <div className='text-xl font-medium'>
                              No transactions found
                            </div>
                            <p className='text-slate-400 max-w-md'>
                              No transactions match your current filters. Try
                              changing your search criteria or clearing filters.
                            </p>
                            {filters.description ||
                            filters.transactionDirection ||
                            filters.transactionType ||
                            filters.startDate ? (
                              <button
                                onClick={clearFilters}
                                className='mt-2 px-4 py-2 bg-teal-600 hover:bg-teal-700 text-white rounded-lg transition-colors duration-200 flex items-center space-x-2'
                              >
                                <Filter size={16} />
                                <span>Clear Filters</span>
                              </button>
                            ) : null}
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
              {filteredTransactions.length > 0 && (
                <tfoot className='bg-slate-800'>
                  <tr>
                    <td
                      colSpan='6'
                      className='px-4 py-4 whitespace-nowrap text-sm font-medium text-right text-slate-300'
                    >
                      Total Amount:
                    </td>
                    <td className='px-4 py-4 whitespace-nowrap text-right text-sm font-medium'>
                      <span
                        className={
                          totalFilteredAmount >= 0
                            ? 'text-emerald-400'
                            : 'text-red-400'
                        }
                      >
                        {totalFilteredAmount >= 0 ? '+' : ''}
                        {totalFilteredAmount.toLocaleString()} PKR
                      </span>
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>

          {/* Pagination */}
          {transactions.length > 0 && (
            <div className='px-4 py-3 border-t border-slate-700'>
              {renderPagination()}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ExpenseIncomeTrack
