import React, { useState, useRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Calendar, ChevronDown, X, Check } from 'lucide-react'

const EnhancedDateFilter = ({
  onDateRangeChange,
  initialStartDate = null,
  initialEndDate = null
}) => {
  const [dateRange, setDateRange] = useState([initialStartDate, initialEndDate])
  const [startDate, endDate] = dateRange
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [manualStartDate, setManualStartDate] = useState('')
  const [manualEndDate, setManualEndDate] = useState('')
  const [customPeriod, setCustomPeriod] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const datePickerRef = useRef(null)

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Initialize manual date fields from props
  useEffect(() => {
    if (initialStartDate) {
      setManualStartDate(formatDateString(initialStartDate))
    }
    if (initialEndDate) {
      setManualEndDate(formatDateString(initialEndDate))
    }
  }, [initialStartDate, initialEndDate])

  // Format date as DD/MM/YYYY
  const formatDateString = date => {
    if (!date) return ''
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  const presetRanges = [
    {
      label: 'Today',
      getValue: () => {
        const today = new Date()
        return [today, today]
      }
    },
    {
      label: 'Last 3 Days',
      getValue: () => {
        const today = new Date()
        const threeDaysAgo = new Date(today.getTime() - 3 * 24 * 60 * 60 * 1000)
        return [threeDaysAgo, today]
      }
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = new Date()
        const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
        return [sevenDaysAgo, today]
      }
    },
    {
      label: `This ${new Date().toLocaleString('default', { month: 'long' })}`,
      getValue: () => {
        const today = new Date()
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        return [firstDay, lastDay]
      }
    },
    {
      label: 'Last Month',
      getValue: () => {
        const today = new Date()
        const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        const lastDay = new Date(today.getFullYear(), today.getMonth(), 0)
        return [firstDay, lastDay]
      }
    },
    {
      label: 'Last 3 Months',
      getValue: () => {
        const today = new Date()
        const threeMonthsAgo = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          1
        )
        return [threeMonthsAgo, today]
      }
    },
    {
      label: 'Last 6 Months',
      getValue: () => {
        const today = new Date()
        const sixMonthsAgo = new Date(
          today.getFullYear(),
          today.getMonth() - 6,
          1
        )
        return [sixMonthsAgo, today]
      }
    },
    {
      label: 'Last 9 Months',
      getValue: () => {
        const today = new Date()
        const nineMonthsAgo = new Date(
          today.getFullYear(),
          today.getMonth() - 9,
          1
        )
        return [nineMonthsAgo, today]
      }
    },
    {
      label: 'Last 1 Year',
      getValue: () => {
        const today = new Date()
        const oneYearAgo = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        )
        return [oneYearAgo, today]
      }
    },
    {
      label: 'Custom Range',
      getValue: () => {
        setCustomPeriod(true)
        return dateRange // Keep current selection
      }
    }
  ]

  // Generate months and years for dropdowns
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = currentYear - 10; i <= currentYear; i++) {
      years.push(i)
    }
    return years
  }

  const years = generateYearOptions()

  // Parse date in DD/MM/YYYY format
  const parseManualDate = dateString => {
    const parts = dateString.split('/')
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10)
      const month = parseInt(parts[1], 10)
      const year = parseInt(parts[2], 10)

      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        const parsedDate = new Date(year, month - 1, day)
        // Validate the date is valid and matches the input
        return parsedDate instanceof Date &&
          !isNaN(parsedDate) &&
          parsedDate.getDate() === day &&
          parsedDate.getMonth() === month - 1 &&
          parsedDate.getFullYear() === year
          ? parsedDate
          : null
      }
    }
    return null
  }

  const handleManualDateSubmit = () => {
    const startDateParsed = parseManualDate(manualStartDate)
    const endDateParsed = parseManualDate(manualEndDate)

    if (startDateParsed && endDateParsed) {
      // Set time to beginning of day for start date
      startDateParsed.setHours(0, 0, 0, 0)

      // Set time to end of day for end date
      endDateParsed.setHours(23, 59, 59, 999)

      const newDateRange = [startDateParsed, endDateParsed]
      setDateRange(newDateRange)
      onDateRangeChange(newDateRange)
      setIsDatePickerOpen(false)
      setCustomPeriod(false)
    } else {
      alert('Please enter dates in DD/MM/YYYY format')
    }
  }

  // Handle date range selection
  const handleDateRangeChange = update => {
    setDateRange(update)
    setManualStartDate(update[0] ? formatDateString(update[0]) : '')
    setManualEndDate(update[1] ? formatDateString(update[1]) : '')
    if (update[0] && update[1]) {
      // Set time to beginning of day for start date
      if (update[0]) {
        update[0].setHours(0, 0, 0, 0)
      }

      // Set time to end of day for end date
      if (update[1]) {
        update[1].setHours(23, 59, 59, 999)
      }

      onDateRangeChange(update)
    }
  }

  // Apply preset range
  const applyPresetRange = range => {
    const newRange = range.getValue()
    if (range.label === 'Custom Range') return

    // Set time to beginning of day for start date
    if (newRange[0]) {
      newRange[0].setHours(0, 0, 0, 0)
    }

    // Set time to end of day for end date
    if (newRange[1]) {
      newRange[1].setHours(23, 59, 59, 999)
    }

    setDateRange(newRange)
    setManualStartDate(formatDateString(newRange[0]))
    setManualEndDate(formatDateString(newRange[1]))
    onDateRangeChange(newRange)
    setIsDatePickerOpen(false)
    setCustomPeriod(false)
  }

  // Apply custom month selection
  const applyCustomMonth = () => {
    const firstDay = new Date(selectedYear, selectedMonth, 1)
    firstDay.setHours(0, 0, 0, 0)

    const lastDay = new Date(selectedYear, parseInt(selectedMonth) + 1, 0)
    lastDay.setHours(23, 59, 59, 999)

    const newRange = [firstDay, lastDay]
    setDateRange(newRange)
    setManualStartDate(formatDateString(firstDay))
    setManualEndDate(formatDateString(lastDay))
    onDateRangeChange(newRange)
    setIsDatePickerOpen(false)
    setCustomPeriod(false)
  }

  // Clear date range
  const clearDateRange = () => {
    setDateRange([null, null])
    setManualStartDate('')
    setManualEndDate('')
    onDateRangeChange([null, null])
    setCustomPeriod(false)
  }

  // Format date display
  const formatDateDisplay = () => {
    if (!startDate && !endDate) return 'Select Date Range'

    if (startDate && endDate) {
      return `${formatDateString(startDate)} - ${formatDateString(endDate)}`
    }

    return startDate
      ? `From ${formatDateString(startDate)}`
      : `Until ${formatDateString(endDate)}`
  }

  return (
    <div className='relative'>
      {/* Date Range Selector Button */}
      <button
        onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
        className='w-full flex items-center justify-between px-4 py-3 rounded-xl 
                   text-white bg-slate-700 border border-slate-600 
                   hover:border-teal-400 focus:outline-none 
                   transition-all duration-300'
      >
        <div className='flex items-center gap-2'>
          <Calendar className='w-5 h-5 text-teal-400' />
          <span className='text-white'>{formatDateDisplay()}</span>
        </div>
        <ChevronDown
          className={`w-4 h-4 text-teal-400 transition-transform duration-300 
                      ${isDatePickerOpen ? 'rotate-180' : ''}`}
        />
      </button>

      {/* Dropdown Container */}
      {isDatePickerOpen && (
        <div
          ref={datePickerRef}
          className='absolute z-50 mt-2 w-full bg-slate-800 rounded-xl 
                     shadow-xl border border-slate-700 p-4 
                     animate-fadeIn'
        >
          {/* Preset Ranges */}
          <div className='grid grid-cols-2 md:grid-cols-3 gap-2 mb-4'>
            {presetRanges.map(range => (
              <button
                key={range.label}
                onClick={() => applyPresetRange(range)}
                className='px-3 py-2 text-xs bg-slate-700 
                           hover:bg-teal-700 text-white 
                           rounded-lg transition-colors'
              >
                {range.label}
              </button>
            ))}
          </div>

          {/* Custom Month Selection */}
          <div className={`mb-4 ${customPeriod ? 'block' : 'hidden'}`}>
            <div className='flex gap-2 mb-2'>
              <select
                value={selectedMonth}
                onChange={e => setSelectedMonth(parseInt(e.target.value))}
                className='flex-1 px-3 py-2 bg-slate-700 text-white rounded-lg border border-slate-600 focus:outline-none focus:border-teal-400'
              >
                {months.map((month, index) => (
                  <option key={month} value={index}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                value={selectedYear}
                onChange={e => setSelectedYear(parseInt(e.target.value))}
                className='flex-1 px-3 py-2 bg-slate-700 text-white rounded-lg border border-slate-600 focus:outline-none focus:border-teal-400'
              >
                {years.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <button
                onClick={applyCustomMonth}
                className='px-3 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-500 transition-colors'
              >
                Apply
              </button>
            </div>
          </div>

          {/* Manual Date Input */}
          <div
            className={`flex gap-2 mb-4 ${customPeriod ? 'block' : 'hidden'}`}
          >
            <div className='flex-1'>
              <label className='block text-xs text-slate-400 mb-1'>
                Start Date (DD/MM/YYYY)
              </label>
              <input
                type='text'
                placeholder='DD/MM/YYYY'
                value={manualStartDate}
                onChange={e => setManualStartDate(e.target.value)}
                className='w-full px-3 py-2 bg-slate-700 text-white 
                         rounded-lg border border-slate-600 
                         focus:outline-none focus:border-teal-400'
              />
            </div>
            <div className='flex-1'>
              <label className='block text-xs text-slate-400 mb-1'>
                End Date (DD/MM/YYYY)
              </label>
              <input
                type='text'
                placeholder='DD/MM/YYYY'
                value={manualEndDate}
                onChange={e => setManualEndDate(e.target.value)}
                className='w-full px-3 py-2 bg-slate-700 text-white 
                         rounded-lg border border-slate-600 
                         focus:outline-none focus:border-teal-400'
              />
            </div>
          </div>

          {/* Date Picker */}
          <div className={customPeriod ? 'block' : 'hidden'}>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRangeChange}
              inline
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              isClearable
              placeholderText='Select date range'
              dateFormat='dd/MM/yyyy'
              className='!w-full !bg-slate-800 !text-white'
              calendarClassName='custom-calendar-dropdown bg-slate-800'
              dayClassName={date => {
                const isSelected =
                  (startDate &&
                    date.toDateString() === startDate.toDateString()) ||
                  (endDate && date.toDateString() === endDate.toDateString())

                const isInRange =
                  startDate && endDate && date >= startDate && date <= endDate

                if (isSelected) return '!bg-teal-400 !text-white rounded-full'
                if (isInRange) return '!bg-teal-900/50 !text-white'
                return '!text-slate-900 hover:!bg-teal-500 hover:!text-white rounded-full' // Darker text
              }}
              wrapperClassName='!bg-slate-800'
              monthClassName={() => 'text-white'}
              weekDayClassName={() => 'text-teal-400'}
            />
          </div>

          {/* Action Buttons */}
          <div
            className={`flex justify-between mt-4 ${
              customPeriod ? 'block' : 'hidden'
            }`}
          >
            <div className='flex gap-2'>
              <button
                onClick={clearDateRange}
                className='flex items-center gap-2 px-4 py-2 
                         bg-red-500/10 text-red-400 
                         hover:bg-red-500/20 rounded-lg 
                         transition-colors'
              >
                <X className='w-4 h-4' />
                Clear
              </button>
              <button
                onClick={handleManualDateSubmit}
                className='flex items-center gap-2 px-4 py-2 
                         bg-teal-500/10 text-teal-400 
                         hover:bg-teal-500/20 rounded-lg 
                         transition-colors'
              >
                <Check className='w-4 h-4' />
                Apply
              </button>
            </div>
            <button
              onClick={() => setIsDatePickerOpen(false)}
              className='px-4 py-2 bg-teal-600 text-white 
                       rounded-lg hover:bg-teal-500 
                       transition-colors'
            >
              Done
            </button>
          </div>

          {/* Add custom styles to fix date color issues */}
          <style jsx>{`
            :global(.react-datepicker__header) {
              background-color: #1e293b !important;
              border-bottom: 1px solid #334155 !important;
            }

            :global(.react-datepicker__current-month),
            :global(.react-datepicker__day-name),
            :global(.react-datepicker__day),
            :global(.react-datepicker__month-text),
            :global(.react-datepicker__year-text),
            :global(.react-datepicker__month-dropdown),
            :global(.react-datepicker__year-dropdown) {
              color: #e2e8f0 !important;
            }

            :global(.react-datepicker) {
              background-color: #1e293b !important;
              border: 1px solid #334155 !important;
            }

            :global(.react-datepicker__month-container) {
              background-color: #1e293b !important;
            }

            :global(.react-datepicker__triangle) {
              border-bottom-color: #1e293b !important;
            }

            :global(.react-datepicker__day--keyboard-selected),
            :global(.react-datepicker__month-text--keyboard-selected),
            :global(.react-datepicker__year-text--keyboard-selected) {
              background-color: #0d9488 !important;
            }

            :global(.react-datepicker__day--selected),
            :global(.react-datepicker__month-text--selected),
            :global(.react-datepicker__year-text--selected) {
              background-color: #0d9488 !important;
            }

            :global(.react-datepicker__month-dropdown),
            :global(.react-datepicker__year-dropdown) {
              background-color: #1e293b !important;
              border: 1px solid #334155 !important;
            }

            :global(.react-datepicker__month-option),
            :global(.react-datepicker__year-option) {
              background-color: #1e293b !important;
              color: #e2e8f0 !important;
            }

            :global(.react-datepicker__month-option:hover),
            :global(.react-datepicker__year-option:hover) {
              background-color: #0d9488 !important;
            }

            :global(.react-datepicker__navigation-icon::before) {
              border-color: #e2e8f0 !important;
            }

            :global(.react-datepicker__day--outside-month) {
              color: #475569 !important;
            }
          `}</style>
        </div>
      )}
    </div>
  )
}

export default EnhancedDateFilter
