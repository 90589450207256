// import React, { useState, useEffect, useRef, useMemo } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import {
//   fetchSocieties,
//   addSociety,
//   removeSociety,
//   updateSocietyAction
// } from '../store/slices/societySlice'
// import { checkActiveUser } from '../store/slices/authSlice'
// import Swal from 'sweetalert2'
// import {
//   Camera,
//   MapPin,
//   Building2,
//   Pencil,
//   Trash2,
//   X,
//   Plus
// } from 'lucide-react'

// const CreateSociety = () => {
//   const dispatch = useDispatch()
//   const { activeUser } = useSelector(state => state.auth)
//   const { societies } = useSelector(state => state.societies)

//   const [societyData, setSocietyData] = useState({
//     name: '',
//     location: '',
//     society_image: '',
//     society_map_image: '',
//     created_by: ''
//   })
//   const [selectedSociety, setSelectedSociety] = useState(null)
//   const [isFieldDisabled, setIsFieldDisabled] = useState(false)

//   const fileInputRef = useRef(null)
//   const fileInputRefMapImage = useRef(null)

//   useEffect(() => {
//     const initialize = async () => {
//       const societiesResult = await dispatch(fetchSocieties())
//       if (societiesResult && societiesResult.error) {
//         console.error('Failed to fetch societies:', societiesResult.error)
//       }
//       const resultAction = await dispatch(checkActiveUser())
//       if (resultAction.payload === null) {
//         Swal.fire({
//           icon: 'warning',
//           title: 'Authentication Required',
//           text: 'Please log in.',
//           background:
//             'linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)',
//           color: '#FACC15',
//           confirmButtonColor: '#FBBF24'
//         })
//       }
//     }

//     initialize()
//   }, [dispatch])

//   const rows = useMemo(
//     () =>
//       societies.map((society, index) => ({
//         id: society._id || `temp-${index}`,
//         name: society.name || '',
//         location: society.location || '',
//         society_image: society.society_image || '',
//         society_map_image: society.society_map_image || '',
//         _id: society._id
//       })),
//     [societies]
//   )

//   const handleInputChange = e => {
//     const { name, value } = e.target
//     setSocietyData(prev => ({ ...prev, [name]: value }))
//   }

//   const handleFileUpload = (e, field) => {
//     const file = e.target.files[0]
//     if (file) {
//       const reader = new FileReader()
//       reader.onloadend = () => {
//         setSocietyData(prev => ({
//           ...prev,
//           [field]: reader.result
//         }))
//       }
//       reader.readAsDataURL(file)
//     }
//   }
//   const handleSubmit = async e => {
//     e.preventDefault()
//     const action = selectedSociety ? updateSocietyAction : addSociety
//     const payload = selectedSociety
//       ? { ...societyData, _id: selectedSociety._id, created_by: activeUser.id }
//       : { ...societyData, created_by: activeUser.id }

//     try {
//       const resultAction = await dispatch(action(payload))
//       if (action.fulfilled.match(resultAction)) {
//         Swal.fire({
//           icon: 'success',
//           title: 'Success!',
//           text: selectedSociety
//             ? 'Society updated successfully!'
//             : 'Society created successfully!',
//           background:
//             'linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)',
//           color: '#5EEAD4',
//           confirmButtonColor: '#008000'
//         })

//         setSocietyData({
//           name: '',
//           location: '',
//           society_image: '',
//           created_by: ''
//         })
//         setSelectedSociety(null)
//         dispatch(fetchSocieties())
//       } else {
//         throw new Error(resultAction.error.message)
//       }
//     } catch (error) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Error!',
//         text: `Failed to save society: ${error.message}`,
//         background:
//           'linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)',
//         color: '#FCA5A5',
//         confirmButtonColor: '#f27474'
//       })
//     }
//   }

//   const handleEdit = society => {
//     setSocietyData({
//       name: society.name,
//       location: society.location,
//       society_image: society.society_image,
//       society_map_image: society.society_map_image,
//       created_by: activeUser.id
//     })
//     setSelectedSociety(society)
//   }

//   const handleDelete = societyId => {
//     Swal.fire({
//       title: 'Are you sure?',
//       text: "You won't be able to revert this!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonText: 'Yes, delete it!',
//       cancelButtonText: 'No, cancel!',
//       background: 'linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)',
//       color: '#5EEAD4',
//       confirmButtonColor: '#FF0000',
//       cancelButtonColor: '#64748b'
//     }).then(result => {
//       if (result.isConfirmed) {
//         dispatch(removeSociety(societyId))
//           .then(() => {
//             Swal.fire({
//               icon: 'info',
//               title: 'Deletion is in progress!',
//               text: 'Request sent to admin.',
//               background:
//                 'linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)',
//               color: '#5EEAD4',
//               confirmButtonColor: '#CCCC00'
//             })
//             dispatch(fetchSocieties())
//           })
//           .catch(() => {
//             Swal.fire({
//               icon: 'error',
//               title: 'Error!',
//               text: 'Failed to delete society.',
//               background:
//                 'linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)',
//               color: '#5EEAD4',
//               confirmButtonColor: '#FF0000'
//             })
//           })
//       }
//     })
//   }

//   return (
//     <div className=' bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 py-12 px-4 sm:px-6 lg:px-8 rounded-2xl shadow-4xl'>
//       <div className='max-w-7xl mx-auto'>
//         <div className='bg-slate-900/50 backdrop-blur-xl rounded-2xl shadow-xl p-6 md:p-8 border border-slate-700/50'>
//           <h1 className='text-3xl font-bold text-teal-300 mb-8 flex items-center gap-2'>
//             <Building2 className='w-8 h-8' />
//             {selectedSociety ? 'Edit Society' : 'Create New Society'}
//           </h1>

//           <form onSubmit={handleSubmit} className='space-y-6'>
//             <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
//               <div className='space-y-2'>
//                 <label className='text-teal-300 text-sm font-medium'>
//                   Society Name
//                 </label>
//                 <input
//                   type='text'
//                   name='name'
//                   value={societyData.name}
//                   onChange={handleInputChange}
//                   className='w-full bg-slate-800/50 border border-slate-700 rounded-lg px-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20'
//                   placeholder='Enter society name'
//                   required
//                 />
//               </div>

//               <div className='space-y-2'>
//                 <label className='text-teal-300 text-sm font-medium'>
//                   Location
//                 </label>
//                 <div className='relative'>
//                   <MapPin className='absolute left-3 top-3 w-5 h-5 text-slate-500' />
//                   <input
//                     type='text'
//                     name='location'
//                     value={societyData.location}
//                     onChange={handleInputChange}
//                     className='w-full bg-slate-800/50 border border-slate-700 rounded-lg pl-10 pr-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20'
//                     placeholder='Enter location'
//                     required
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className='space-y-2'>
//               <label className='text-teal-300 text-sm font-medium'>
//                 Society Image
//               </label>
//               <div className='flex items-center gap-4'>
//                 <input
//                   type='text'
//                   name='society_image'
//                   value={societyData.society_image}
//                   onChange={handleInputChange}
//                   disabled={isFieldDisabled}
//                   className='flex-1 bg-slate-800/50 border border-slate-700 rounded-lg px-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20'
//                   placeholder='Image URL or upload'
//                 />
//                 <input
//                   type='file'
//                   ref={fileInputRef}
//                   className='hidden'
//                   accept='image/*'
//                   onChange={e => handleFileUpload(e, 'society_image')}
//                 />
//                 <button
//                   type='button'
//                   onClick={() => fileInputRef.current?.click()}
//                   className='p-2.5 bg-teal-300/10 rounded-lg text-teal-300 hover:bg-teal-300/20 transition-colors'
//                 >
//                   <Camera className='w-5 h-5' />
//                 </button>
//                 {isFieldDisabled && (
//                   <button
//                     type='button'
//                     onClick={() => {
//                       setSocietyData(prev => ({
//                         ...prev,
//                         society_image: ''
//                       }))
//                       setIsFieldDisabled(false)
//                     }}
//                     className='p-2.5 bg-red-500/10 rounded-lg text-red-500 hover:bg-red-500/20 transition-colors'
//                   >
//                     <X className='w-5 h-5' />
//                   </button>
//                 )}
//               </div>
//             </div>

//             <div className='space-y-2'>
//               <label className='text-teal-300 text-sm font-medium'>
//                 Society Map Image
//               </label>
//               <div className='flex items-center gap-4'>
//                 <input
//                   type='text'
//                   name='society_map_image'
//                   value={societyData.society_map_image}
//                   onChange={handleInputChange}
//                   disabled={isFieldDisabled}
//                   className='flex-1 bg-slate-800/50 border border-slate-700 rounded-lg px-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20'
//                   placeholder='Map Image URL or upload'
//                 />
//                 <input
//                   type='file'
//                   ref={fileInputRefMapImage}
//                   className='hidden'
//                   accept='image/*'
//                   onChange={e => handleFileUpload(e, 'society_map_image')}
//                 />
//                 <button
//                   type='button'
//                   onClick={() => fileInputRefMapImage.current?.click()}
//                   className='p-2.5 bg-teal-300/10 rounded-lg text-teal-300 hover:bg-teal-300/20 transition-colors'
//                 >
//                   <Camera className='w-5 h-5' />
//                 </button>
//               </div>
//             </div>

//             <button
//               type='submit'
//               className='w-full md:w-auto px-6 py-3 bg-teal-300 text-slate-900 rounded-lg font-medium hover:bg-teal-200 transition-colors flex items-center justify-center gap-2'
//             >
//               <Plus className='w-5 h-5' />
//               {selectedSociety ? 'Save Changes' : 'Create Society'}
//             </button>
//           </form>

//           <div className='mt-12'>
//             <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
//               {rows.map(society => (
//                 <div
//                   key={society.id}
//                   className='bg-slate-800/50 border border-slate-700/50 rounded-xl p-6 hover:bg-slate-800/70 transition-colors'
//                 >
//                   <div className='flex items-start justify-between mb-4'>
//                     <div className='flex items-center gap-4'>
//                       <img
//                         src={
//                           society.society_image || '/default_placeholder.jpg'
//                         }
//                         alt={society.name}
//                         className='w-12 h-12 rounded-full object-cover border-2 border-teal-300/20'
//                       />
//                       <div>
//                         <h3 className='text-teal-300 font-medium'>
//                           {society.name}
//                         </h3>
//                         <p className='text-slate-400 text-sm flex items-center gap-1'>
//                           <MapPin className='w-4 h-4' />
//                           {society.location}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className='flex items-center gap-2'>
//                     <button
//                       onClick={() => handleEdit(society)}
//                       className='flex-1 py-2 px-4 bg-teal-300/10 text-teal-300 rounded-lg hover:bg-teal-300/20 transition-colors flex items-center justify-center gap-2'
//                     >
//                       <Pencil className='w-4 h-4' />
//                       Edit
//                     </button>
//                     <button
//                       onClick={() => handleDelete(society._id)}
//                       className='flex-1 py-2 px-4 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors flex items-center justify-center gap-2'
//                     >
//                       <Trash2 className='w-4 h-4' />
//                       Delete
//                     </button>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default CreateSociety

import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSocieties,
  addSociety,
  removeSociety,
  updateSocietyAction,
} from "../store/slices/societySlice";
import { checkActiveUser } from "../store/slices/authSlice";
import Swal from "sweetalert2";
import {
  Camera,
  MapPin,
  Building2,
  Pencil,
  Trash2,
  X,
  Plus,
  Eye,
  Map,
  AlertCircle,
} from "lucide-react";

const CreateSociety = () => {
  const dispatch = useDispatch();
  const { activeUser } = useSelector((state) => state.auth);
  const { societies, loading: societiesLoading } = useSelector(
    (state) => state.societies
  );
  const [societyData, setSocietyData] = useState({
    name: "",
    location: "",
    created_by: "",
  });

  const [societyImageFile, setSocietyImageFile] = useState(null);
  const [societyMapFile, setSocietyMapFile] = useState(null);
  const [societyImagePreview, setSocietyImagePreview] = useState("");
  const [societyMapPreview, setSocietyMapPreview] = useState("");

  const [selectedSociety, setSelectedSociety] = useState(null);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const [viewingMode, setViewingMode] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  const fileInputRef = useRef(null);
  const fileInputRefMapImage = useRef(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        const resultAction = await dispatch(checkActiveUser());
        if (resultAction.payload === null) {
          showAuthAlert();
          return;
        }

        // Fetch societies to ensure initial data
        await dispatch(fetchSocieties());

        // Update initial load state after fetching societies
        setIsInitialLoad(false);
      } catch (error) {
        console.error("Initialization error:", error);
        showErrorAlert("An error occurred during initialization.");
        setIsInitialLoad(false); // Ensure button becomes clickable even if there's an error
      }
    };

    initialize();
  }, [dispatch]);

  const rows = useMemo(
    () =>
      societies.map((society, index) => ({
        id: society._id || `temp-${index}`,
        name: society.name || "",
        location: society.location || "",
        society_image: society.society_image || "",
        society_map_image: society.society_map_image || "",
        plotCount: society.plots?.length || 0,
        _id: society._id,
        created_by: society.created_by || "",
      })),
    [societies]
  );

  const showAuthAlert = () => {
    Swal.fire({
      icon: "warning",
      title: "Authentication Required",
      text: "Please log in to manage societies.",
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      color: "#FACC15",
      confirmButtonColor: "#FBBF24",
    });
  };

  const showErrorAlert = (message) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: message,
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      color: "#FCA5A5",
      confirmButtonColor: "#f27474",
    });
  };

  const showSuccessAlert = (message) => {
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: message,
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      color: "#5EEAD4",
      confirmButtonColor: "#008000",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSocietyData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        showErrorAlert("File size exceeds 5MB limit.");
        return;
      }

      // Check file type
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        showErrorAlert("Only JPEG, JPG and PNG files are allowed.");
        return;
      }

      // Set file for FormData upload
      if (field === "society_image") {
        setSocietyImageFile(file);

        // Create preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setSocietyImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (field === "society_map_image") {
        setSocietyMapFile(file);

        // Create preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setSocietyMapPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const validateForm = () => {
    if (!societyData.name.trim()) {
      showErrorAlert("Society name is required.");
      return false;
    }

    if (!societyData.location.trim()) {
      showErrorAlert("Location is required.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (!activeUser || !activeUser.id) {
      showAuthAlert();
      return;
    }

    try {
      // Set creating state
      setIsCreating(true);

      // Create FormData
      const formData = new FormData();

      // Add basic fields
      formData.append("name", societyData.name);
      formData.append("location", societyData.location);
      formData.append("created_by", activeUser.id);

      // Add image files if they exist
      if (societyImageFile) {
        formData.append("society_image", societyImageFile);
      }

      if (societyMapFile) {
        formData.append("society_map_image", societyMapFile);
      }

      // For update operations, add the ID
      if (selectedSociety) {
        formData.append("_id", selectedSociety._id);
      }

      // Determine the action
      const action = selectedSociety
        ? updateSocietyAction({
            _id: selectedSociety._id, // Pass the ID
            data: formData, // Include the FormData as payload
          })
        : addSociety(formData);

      // Dispatch the action and handle result
      const resultAction = await dispatch(action);

      if (resultAction.type.endsWith("/fulfilled")) {
        showSuccessAlert(
          selectedSociety
            ? "Society updated successfully!"
            : "Society created successfully! The request has been submitted for admin review."
        );

        // Reset form
        setSocietyData({
          name: "",
          location: "",
          created_by: "",
        });
        setSocietyImageFile(null);
        setSocietyMapFile(null);
        setSocietyImagePreview("");
        setSocietyMapPreview("");
        setSelectedSociety(null);
        setIsInitialLoad(false);

        // Fetch societies after successful creation
        await dispatch(fetchSocieties());
      } else {
        throw new Error(resultAction.error.message);
      }
    } catch (error) {
      showErrorAlert(`Failed to save society: ${error.message}`);
    } finally {
      // Always reset creating state
      setIsCreating(false);
    }
  };

  const handleEdit = (society) => {
    setViewingMode(null);
    setSocietyData({
      name: society.name,
      location: society.location,
      created_by: activeUser?.id || "",
    });

    // For edit operations, set the image previews from existing URLs
    setSocietyImagePreview(society.society_image || "");
    setSocietyMapPreview(society.society_map_image || "");

    // Reset the file inputs since we're editing
    setSocietyImageFile(null);
    setSocietyMapFile(null);

    setSelectedSociety(society);
  };

  const handleDelete = (societyId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action will send a deletion request to an administrator. Do you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, request deletion",
      cancelButtonText: "Cancel",
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      color: "#5EEAD4",
      confirmButtonColor: "#FF0000",
      cancelButtonColor: "#64748b",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeSociety(societyId))
          .then(() => {
            Swal.fire({
              icon: "info",
              title: "Deletion Request Sent",
              text: "Your request to delete this society has been sent to an administrator for approval.",
              background:
                "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
              color: "#5EEAD4",
              confirmButtonColor: "#CCCC00",
            });
            dispatch(fetchSocieties());
          })
          .catch((error) => {
            showErrorAlert(
              `Failed to request society deletion: ${error.message}`
            );
          });
      }
    });
  };

  const handleViewSociety = (society, mode) => {
    setViewingMode(mode);
    setSelectedSociety(society);
  };

  const renderViewMode = () => {
    if (!selectedSociety || !viewingMode) return null;

    if (viewingMode === "details") {
      return (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-50 p-4">
          <div className="bg-slate-800 rounded-xl shadow-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-teal-300">
                  Society Details
                </h2>
                <button
                  onClick={() => setViewingMode(null)}
                  className="p-2 bg-slate-700 rounded-full hover:bg-slate-600 transition-colors"
                >
                  <X className="w-5 h-5 text-teal-300" />
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <h3 className="text-sm font-medium text-slate-400">
                    Society Name
                  </h3>
                  <p className="text-lg text-teal-300">
                    {selectedSociety.name}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-slate-400">
                    Location
                  </h3>
                  <p className="text-lg text-teal-300 flex items-center gap-2">
                    <MapPin className="w-4 h-4" />
                    {selectedSociety.location}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-slate-400">
                    Total Plots
                  </h3>
                  <p className="text-lg text-teal-300">
                    {selectedSociety.plotCount || "No plots available"}
                  </p>
                </div>

                {selectedSociety.created_by && (
                  <div>
                    <h3 className="text-sm font-medium text-slate-400">
                      Created By
                    </h3>
                    <p className="text-lg text-teal-300">
                      {selectedSociety.created_by.username || "Unknown"}
                    </p>
                  </div>
                )}
              </div>

              <div className="flex gap-4 mt-8">
                <button
                  onClick={() => handleEdit(selectedSociety)}
                  className="flex-1 py-2 px-4 bg-teal-300/10 text-teal-300 rounded-lg hover:bg-teal-300/20 transition-colors flex items-center justify-center gap-2"
                >
                  <Pencil className="w-4 h-4" />
                  Edit Society
                </button>
                <button
                  onClick={() => handleDelete(selectedSociety._id)}
                  className="flex-1 py-2 px-4 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors flex items-center justify-center gap-2"
                >
                  <Trash2 className="w-4 h-4" />
                  Request Deletion
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (viewingMode === "image") {
      return (
        <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4">
          <div className="relative max-w-4xl w-full">
            <button
              onClick={() => setViewingMode(null)}
              className="absolute -top-12 right-0 p-2 bg-slate-700 rounded-full hover:bg-slate-600 transition-colors"
            >
              <X className="w-5 h-5 text-white" />
            </button>

            <div className="bg-slate-900 p-2 rounded-xl">
              <img
                src={
                  selectedSociety.society_image || "/default_placeholder.jpg"
                }
                alt={selectedSociety.name}
                className="w-full h-auto max-h-[80vh] object-contain rounded-lg"
              />
            </div>

            <h3 className="text-center text-white mt-4 font-medium">
              {selectedSociety.name} - Society Image
            </h3>
          </div>
        </div>
      );
    }

    if (viewingMode === "map") {
      return (
        <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4">
          <div className="relative max-w-4xl w-full">
            <button
              onClick={() => setViewingMode(null)}
              className="absolute -top-12 right-0 p-2 bg-slate-700 rounded-full hover:bg-slate-600 transition-colors"
            >
              <X className="w-5 h-5 text-white" />
            </button>

            <div className="bg-slate-900 p-2 rounded-xl">
              {selectedSociety.society_map_image ? (
                <img
                  src={selectedSociety.society_map_image}
                  alt={`${selectedSociety.name} Map`}
                  className="w-full h-auto max-h-[80vh] object-contain rounded-lg"
                />
              ) : (
                <div className="h-[50vh] flex items-center justify-center text-slate-400">
                  <div className="text-center p-6">
                    <Map className="w-16 h-16 mx-auto mb-4 opacity-40" />
                    <p>No map image available for this society</p>
                  </div>
                </div>
              )}
            </div>

            <h3 className="text-center text-white mt-4 font-medium">
              {selectedSociety.name} - Society Map
            </h3>
          </div>
        </div>
      );
    }

    return null;
  };

  const resetForm = () => {
    setSocietyData({
      name: "",
      location: "",
      created_by: "",
    });
    setSocietyImageFile(null);
    setSocietyMapFile(null);
    setSocietyImagePreview("");
    setSocietyMapPreview("");
    setSelectedSociety(null);
    setIsFieldDisabled(false);
  };

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 py-12 px-4 sm:px-6 lg:px-8 rounded-2xl shadow-4xl">
      <div className="max-w-7xl mx-auto">
        <div className="bg-slate-900/50 backdrop-blur-xl rounded-2xl shadow-xl p-6 md:p-8 border border-slate-700/50">
          {societies.length === 0 && (
            <div className="text-center py-12">
              <Building2 className="w-24 h-24 mx-auto mb-6 text-teal-300" />
              <h2 className="text-3xl font-bold text-teal-300 mb-4">
                Welcome! Create Your First Society
              </h2>
              <p className="text-slate-400 max-w-md mx-auto mb-8">
                It looks like you haven't created any societies yet. Get started
                by filling out the form below to create your first society.
              </p>
            </div>
          )}

          {/* Rest of the existing form and content */}
          <h1 className="text-3xl font-bold text-teal-300 mb-8 flex items-center gap-2">
            <Building2 className="w-8 h-8" />
            {selectedSociety ? "Edit Society" : "Create New Society"}
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="text-teal-300 text-sm font-medium">
                  Society Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={societyData.name}
                  onChange={handleInputChange}
                  className="w-full bg-slate-800/50 border border-slate-700 rounded-lg px-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20"
                  placeholder="Enter society name"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="text-teal-300 text-sm font-medium">
                  Location
                </label>
                <div className="relative">
                  <MapPin className="absolute left-3 top-3 w-5 h-5 text-slate-500" />
                  <input
                    type="text"
                    name="location"
                    value={societyData.location}
                    onChange={handleInputChange}
                    className="w-full bg-slate-800/50 border border-slate-700 rounded-lg pl-10 pr-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20"
                    placeholder="Enter location"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <label className="text-teal-300 text-sm font-medium">
                Society Image
              </label>
              <div className="flex items-center gap-4">
                <div className="flex-1 bg-slate-800/50 border border-slate-700 rounded-lg px-4 py-2.5 text-slate-400 truncate">
                  {societyImageFile
                    ? societyImageFile.name
                    : "No file selected"}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/jpeg,image/jpg,image/png"
                  onChange={(e) => handleFileUpload(e, "society_image")}
                />
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="p-2.5 bg-teal-300/10 rounded-lg text-teal-300 hover:bg-teal-300/20 transition-colors"
                >
                  <Camera className="w-5 h-5" />
                </button>
                {societyImageFile && (
                  <button
                    type="button"
                    onClick={() => {
                      setSocietyImageFile(null);
                      setSocietyImagePreview("");
                    }}
                    className="p-2.5 bg-red-500/10 rounded-lg text-red-500 hover:bg-red-500/20 transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                )}
              </div>
              {societyImagePreview && (
                <div className="mt-2 relative w-32 h-32 bg-slate-800 rounded-lg overflow-hidden border border-slate-700">
                  <img
                    src={societyImagePreview}
                    alt="Society preview"
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </div>

            <div className="space-y-2">
              <label className="text-teal-300 text-sm font-medium">
                Society Map Image
              </label>
              <div className="flex items-center gap-4">
                <div className="flex-1 bg-slate-800/50 border border-slate-700 rounded-lg px-4 py-2.5 text-slate-400 truncate">
                  {societyMapFile ? societyMapFile.name : "No file selected"}
                </div>
                <input
                  type="file"
                  ref={fileInputRefMapImage}
                  className="hidden"
                  accept="image/jpeg,image/jpg,image/png"
                  onChange={(e) => handleFileUpload(e, "society_map_image")}
                />
                <button
                  type="button"
                  onClick={() => fileInputRefMapImage.current?.click()}
                  className="p-2.5 bg-teal-300/10 rounded-lg text-teal-300 hover:bg-teal-300/20 transition-colors"
                >
                  <Camera className="w-5 h-5" />
                </button>
                {societyMapFile && (
                  <button
                    type="button"
                    onClick={() => {
                      setSocietyMapFile(null);
                      setSocietyMapPreview("");
                    }}
                    className="p-2.5 bg-red-500/10 rounded-lg text-red-500 hover:bg-red-500/20 transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                )}
              </div>
              {societyMapPreview && (
                <div className="mt-2 relative w-32 h-32 bg-slate-800 rounded-lg overflow-hidden border border-slate-700">
                  <img
                    src={societyMapPreview}
                    alt="Map preview"
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </div>

            <div className="flex flex-wrap gap-4">
              <button
                type="submit"
                className="px-6 py-3 bg-teal-300 text-slate-900 rounded-lg font-medium hover:bg-teal-200 transition-colors flex items-center gap-2"
                disabled={
                  isCreating || (isInitialLoad && societies.length === 0)
                }
              >
                {isCreating || (isInitialLoad && societies.length === 0) ? (
                  <>
                    <span className="animate-spin inline-block w-5 h-5 border-2 border-slate-900 border-t-transparent rounded-full"></span>
                    {selectedSociety ? "Saving..." : "Creating..."}
                  </>
                ) : (
                  <>
                    <Plus className="w-5 h-5" />
                    {selectedSociety ? "Save Changes" : "Create Society"}
                  </>
                )}
              </button>
              {selectedSociety && (
                <button
                  type="button"
                  onClick={resetForm}
                  className="px-6 py-3 bg-slate-700 text-slate-300 rounded-lg font-medium hover:bg-slate-600 transition-colors"
                >
                  Cancel
                </button>
              )}
            </div>
          </form>

          {!selectedSociety && (
            <div className="mt-6 p-4 bg-blue-900/20 border border-blue-800/30 rounded-lg flex gap-3 items-start">
              <AlertCircle className="w-6 h-6 text-blue-300 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-100">
                <p className="font-medium text-blue-300 mb-1">
                  Administrator Approval Required
                </p>
                <p>
                  Creating a new society or requesting deletion will require
                  administrator approval. You will be notified once your request
                  has been processed.
                </p>
              </div>
            </div>
          )}

          <div className="mt-12">
            <h2 className="text-xl font-semibold text-teal-300 mb-6 flex items-center gap-2">
              <Building2 className="w-6 h-6" />
              Society List
            </h2>

            {societiesLoading && (
              <div className="flex justify-center items-center py-12">
                <div className="animate-spin w-10 h-10 border-4 border-teal-300 border-t-transparent rounded-full"></div>
              </div>
            )}

            {!societiesLoading && rows.length === 0 && (
              <div className="bg-slate-800/50 border border-slate-700/50 rounded-xl p-12 text-center">
                <Building2 className="w-16 h-16 mx-auto mb-4 text-slate-500" />
                <h3 className="text-teal-300 text-lg font-medium mb-2">
                  No Societies Found
                </h3>
                <p className="text-slate-400 max-w-md mx-auto">
                  No societies have been created yet. Use the form above to
                  create your first society.
                </p>
              </div>
            )}

            {!societiesLoading && rows.length > 0 && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {rows.map((society) => (
                  <div
                    key={society.id}
                    className="bg-slate-800/50 border border-slate-700/50 rounded-xl p-6 hover:bg-slate-800/70 transition-colors"
                  >
                    <div className="flex items-start justify-between mb-4">
                      <div className="flex items-center gap-4">
                        <div className="relative group">
                          <img
                            src={
                              society.society_image ||
                              "/default_placeholder.jpg"
                            }
                            alt={society.name}
                            className="w-12 h-12 rounded-full object-cover"
                          />
                          <div className="absolute inset-0 bg-black/50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                            <Eye className="w-5 h-5 text-white" />
                          </div>
                        </div>
                        <div>
                          <h3 className="text-teal-300 font-medium">
                            {society.name}
                          </h3>
                          <p className="text-slate-400 text-sm flex items-center gap-1">
                            <MapPin className="w-4 h-4" />
                            {society.location}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 flex justify-between text-sm">
                      <span className="text-slate-400">
                        Plots:{" "}
                        <span className="text-teal-300">
                          {society.plotCount || 0}
                        </span>
                      </span>

                      {society.society_map_image && (
                        <button
                          onClick={() => handleViewSociety(society, "map")}
                          className="text-teal-300 hover:text-teal-200 flex items-center gap-1"
                        >
                          <Map className="w-4 h-4" />
                          View Map
                        </button>
                      )}
                    </div>

                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => handleViewSociety(society, "details")}
                        className="flex-1 py-2 px-4 bg-slate-700 text-slate-300 rounded-lg hover:bg-slate-600 transition-colors flex items-center justify-center gap-2"
                      >
                        <Eye className="w-4 h-4" />
                        Details
                      </button>
                      <button
                        onClick={() => handleEdit(society)}
                        className="flex-1 py-2 px-4 bg-teal-300/10 text-teal-300 rounded-lg hover:bg-teal-300/20 transition-colors flex items-center justify-center gap-2"
                      >
                        <Pencil className="w-4 h-4" />
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(society._id)}
                        className="flex-1 py-2 px-4 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors flex items-center justify-center gap-2"
                      >
                        <Trash2 className="w-4 h-4" />
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {renderViewMode()}
    </div>
  );
};

export default CreateSociety;
